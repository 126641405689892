@import "./../../../../../scss/pc/reset";

.Dsixth {
	position: relative;
    width: 100%;
    color: #000;
}
.leftCell {
    display: inline-block;
    width: 50%;
    padding: 50px 0;
    text-align: center;
    background: #f0f0f0;
    i {
        display: inline-block;
        width: 45px;
        height: 45px;
        margin-bottom: 10px;
        background: url(./temperature.png) no-repeat center;
        background-size: 100%;
    }
}
.rightCell {
    @extend .leftCell;
    background: #f7f7f7;
    i {
        background: url(./humidity.png) no-repeat center;
        background-size: 100%;
    }
}
.desc {
    @extend %little;
    opacity: .7;
}
.text {
    @extend %normal;
    line-height: 1.5;
    opacity: .8;
}