@import "./../../../../scss/pc/reset";

.box {
    position: relative;
    width: 100%;
    background: rgb(238,238,238);

}

.store {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .title {
        margin-top: 60px;
        font-size: 24px;
        font-weight: 400;
    }
    ul {
        width: 1251px;
        margin: 0 auto;
        padding: 60px 0 0px 0;
        text-align: center;
        @extend %clearfix;

        li {
            float: left;
            // border: 1px #eeeeee solid;
            border-radius: 5px;
            width: 387px;
            height: 127px;
            margin: 15px;
            // padding: 40px;
            box-sizing: border-box;
            background-color: #fff;
            cursor: pointer;

            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            a{
                display: flex;
                width: 100%;
                padding: 40px;
                .desc {
                        margin-left: 40px;
                        font-size: 16px;
                        text-align: left;
                        vertical-align: middle;
                        line-height: 1.3;
                        color: rgb(130, 130, 130);
                    }
                
                    .magpie {
                        width: 40px;
                        height: 40px;
                        background: url('./magpie.png') center no-repeat;
                        background-size: 100%;
                    }
                
                    .dany {
                        width: 40px;
                        height: 40px;
                        background: url('./dany.png') center no-repeat;
                        background-size: 100%;
                    }
                
                    .dove {
                        width: 40px;
                        height: 40px;
                        background: url('./dove.png') center no-repeat;
                        background-size: 100%;
                    }
                
                    .sparrow {
                        width: 40px;
                        height: 40px;
                        background: url('./sparrow.png') center no-repeat;
                        background-size: 100%;
                    }
                
                    .hodor {
                        width: 40px;
                        height: 40px;
                        background: url('./hodor.png') center no-repeat;
                        background-size: 100%;
                    }
                
                    .parrot {
                        width: 40px;
                        height: 40px;
                        background: url('./parrot.png') center no-repeat;
                        background-size: 100%;
                    }
                
                    .pheasant {
                        width: 40px;
                        height: 40px;
                        background: url('./pheasant.png') center no-repeat;
                        background-size: 100%;
                    }
                
                    .goose {
                        width: 40px;
                        height: 40px;
                        background: url('./goose.png') center no-repeat;
                        background-size: 100%;
                    }
                
                    .duck2 {
                        width: 40px;
                        height: 40px;
                        background: url('./duck2.png') center no-repeat;
                        background-size: 100%;
                    }
                    .chicken {
                        width: 40px;
                        height: 40px;
                        background: url('./chicken.png') center no-repeat;
                        background-size: 100%;
                    }
                
                    .frog {
                        width: 40px;
                        height: 40px;
                        background: url('./frog.png') center no-repeat;
                        background-size: 100%;
                    }
                
                    .snow {
                        width: 40px;
                        height: 40px;
                        background: url('./snow.png') center no-repeat;
                        background-size: 100%;
                    }
                    
            }
            
                
        }

        > :hover {
            // top: -5px;
            // border: 1px #ffffff solid;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
            // transition: top .5s;
        }

        
        
    }
}

.little {
    @extend %little;
    opacity: .8;
}

.normal {
    @extend %normal;
    opacity: .8;
}

.large {
    @extend %large;
    opacity: .9;
}