@import "./../../../../../scss/pc/reset";

.Gthird {
    position: relative;
    width: 100%;
    height: 620px;
    padding: 70px 0;
    text-align: center;
    color: #000000;
    background: #fff;
}
.title {
    @extend %large;
    padding-top: 80px;
}
.wrap {
    background: url(./G_third.png) no-repeat left;
    background-size: 60% auto;
    max-width: 1280px;
    width: 100%;
    margin: 0 0 0 -640px;
    position: absolute;
    left: 50%;
    height: 620px;
}
.text {
    position: absolute;
    top: 206px;
    right: 40px;
    padding-top: 60px;
    opacity: 0;
    transition: opacity .8s ease-in, top .8s;
    text-align: right;
    span {
        display: block;
    }
}
.video {
    position: absolute;
    bottom: 100px;
    right: 40px;
    padding-top: 20px;
    background: #fff;
    border-radius: 15px;
    text-align: center;
    color: #424242;
    
    opacity: 0;
    transition: opacity .8s ease-in, bottom .8s;
    li {
        width: 40%;
        display: inline-block;
    }
    i {
        display: block;
        margin: 40px auto 14px auto;
    }
}
.third_video {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    background: url(./third_video.gif) no-repeat center;
    background-size: cover;
}
.normal {
    @extend %normal;
    font-weight: normal;
    opacity: .8;
}
.large {
    @extend %large;
}