@import "./../../../../../scss/pc/reset";

.Gsecond {
    position: relative;
    width: 100%;
    height: 650px;
    background: url(./G_second.png) no-repeat right;
    background-size: cover;
    text-align: center;
    color: #424242;
}
.wrap {
    position: relative;
    width: 100%;
    max-width: 1280px;
    height: 100%;
    margin: 0 auto;
}
.img {
    position: absolute;
    bottom: -20px;
    margin-bottom: 100px;
    left: 10px;
    width: 429px;
    height: 374px;
    
    background: url(./G_second_img.png) no-repeat center;
    background-size: 100%;
    opacity: 0;
    transition: opacity .8s ease-in, bottom .8s;
}
.img_tw {
    position: absolute;
    bottom: -20px;
    margin-bottom: 100px;
    left: 10px;
    width: 429px;
    height: 374px;
    
    background: url(./G_second_img_tw.png) no-repeat center;
    background-size: 100%;
    opacity: 0;
    transition: opacity .8s ease-in, bottom .8s;
}
.img_en {
    position: absolute;
    bottom: -20px;
    margin-bottom: 100px;
    left: 10px;
    width: 429px;
    height: 374px;
    
    background: url(./G_second_img_en.png) no-repeat center;
    background-size: 100%;
    opacity: 0;
    transition: opacity .8s ease-in, bottom .8s;
}