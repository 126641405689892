@import './../../../../../scss/pc/reset';

.Snineth {
  width: 100%;
  height: 800px;
  background: url(./bg.png) center no-repeat;
  background-size: cover;
  color: #000;
  .wrap {
    padding: 100px 0;
    .text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin: auto;
      width: 700px;
      text-align: center;
      .title {
        @extend %large_new;
        padding-bottom: 10px;
        .footnote {
          position: relative;
          vertical-align: baseline;
          font-size: 24px;
          opacity: .7;
          a {
            vertical-align: inherit;
            color: inherit;
          }
          a:hover {
            color: #06c;
          }
        }
      }
      .desc {
        @extend %normal_new;
        padding-bottom: 50px;
        .footnote {
          position: relative;
          vertical-align: baseline;
          font-size: 9px;
          opacity: .7;
          a {
            vertical-align: inherit;
            color: inherit;
          }
          a:hover {
            color: #06c;
          }
        }
      }
    }
  }
}

.Snineth_en {
  @extend .Snineth;
  height: 855px;
  background: url(./bg_en.png) center no-repeat;
  background-size: cover;
  .wrap {
    .text {
      width: 600px;
    }
  }
}
