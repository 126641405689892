@import "./../../../../../scss/pc/reset";

.Gfifth {
    position: relative;
    width: 100%;
    background-color: #f1f1f1;
    text-align: center;
    color: #424242;
}
.title {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 150px;
    opacity: 0;
    transition: opacity .8s ease-in, top .8s;
    @extend %large;
}
.img {
    li {
        display: inline-block;
        margin: 210px 40px 60px 40px;
        span {
            display: block;
            margin-top: 20px;
        }
    }
}
.goose {
    display: block;
    width: 361px;
    height: 361px;
    background: url(./fifth_goose.png) no-repeat center;
    background-size: 100%;
}
.duck {
    display: block;
    width: 361px;
    height: 361px;
    background: url(./fifth_duck.jpg) no-repeat center;
    background-size: 100%;
}
.normal {
    @extend %normal;
}
.large {
    @extend %large;
}