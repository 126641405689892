@import "./../../../../../scss/pc/reset";

.Sthird {
    position: relative;
    color: #000;
    background-color: #e5e5e5;
}
.wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 70px;
    .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 100px;
        width: 620px;
        text-align: left;
        box-sizing: border-box;
        .large {
            @extend %large_new;
            padding-bottom: 2px;
        }
        .normal {
            @extend %normal_new;
            margin-top: 8px;
            opacity: .6;
            .footnote {
                position: relative;
                vertical-align: baseline;
                font-size: 9px;
                opacity: .7;
                a {
                  vertical-align: inherit;
                  color: inherit;
                }
                a:hover {
                  color: #06c;
                }
              }
        }
        .functions {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            width: 450px;
            .function_item {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 30px;
                width: 150px;
                .icon1, .icon2, .icon3, .icon4, .icon5, .icon6 {
                    display: block;
                    margin-bottom: 15px;
                    width: 70px;
                    height: 70px;
                }
                .icon1 {
                    background: url(./3.png) center center;
                    background-size: 70px 70px;
                }
                .icon2 {
                    background: url(./4.png) center center;
                    background-size: 70px 70px;
                }
                .icon3 {
                    background: url(./5.png) center center;
                    background-size: 70px 70px;
                }
                .icon4 {
                    background: url(./6.png) center center;
                    background-size: 70px 70px;
                }
                .icon5 {
                    background: url(./7.png) center center;
                    background-size: 70px 70px;
                }
                .icon6 {
                    background: url(./8.png) center center;
                    background-size: 70px 70px;
                }
            }
        }
    }
    .text_en {
        @extend .text;
        align-items: center;
        text-align: center;
        padding-top: 100px;
    }
}
