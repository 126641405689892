@import "./../../../../../scss/pc/reset";

.Gsixth {
	position: relative;
    width: 100%;
    height: 546px;
    color: #424242;
    background-image: linear-gradient(to right, #ced8db , #cfdadd);
    // background: url(./bg_3.png) no-repeat center;
    // background-size: contain;
}
.title {
    position: absolute;
    top: 278px;
    left: 48%;
    opacity: 0;
    transition: opacity .8s ease-in, top .8s;
    @extend %large;
    span {
        display: block;
    }
}
.warp {
    position: relative;
    margin: auto;
    width: 1280px;
    height: 546px;
}
.img {
    position: absolute;
    top: 78px;
    left: 16%;
    width: 348px;
    height: 422.5px;
    background: url(./mix.png) no-repeat center;
    background-size: auto 100%;
    
}
.bg_2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 700px;
    background: url(./bg_2.png) no-repeat center;
    background-size: auto 100%;
    opacity: 0;
    transition: opacity .8s;
}
.normal {
    font-size: 20px;
    font-weight: normal;
    opacity: .8;
    // @extend %normal;
}
.large {
    @extend %large;
}