@import "./../../../../../scss/pc/reset";

.Sfirst {
    position: relative;
    width: 100%;
    height: 720px;
    color: #fff;
    overflow: hidden;
    background-image: url(./bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
}
.Sfirst_en {
    @extend .Sfirst;
    height: 720px;
    background-image: url(./bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
}
.text {
    position: absolute;
    top: 50px;
    opacity: 0;
    width: 100%;
    padding-top: 100px;
    line-height: 1;
    text-align: right;
    text-align: center;
    transition: opacity .8s, top .8s;
    z-index: 4;
}
.large {
    @extend %large;
    line-height: 1.2;
    font-size: 45px;
    font-weight: bold;
}
.normal {
    @extend %little;
    margin-top: 8px;
    opacity: .6;
    .line {
        margin: 0 5px;
    }
}
// .bg {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     z-index: 2;
//     top: 0;
//     left: 0;
//     background: #000;
//     opacity: .7;
// }
.img {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    background: #000;
}
.video {
    display: none;
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -600px;
    background: #000;
}
.IF {
    position: absolute;
    right: 50px;
    bottom: 50px;
    z-index: 2;
    display: block;
    width: 95px;
    height: 48px;
    background: url(./mi.png) no-repeat center;
    background-size: 95px 48px;
}
.IF_en {
    @extend .IF;
    width: 126px;
    height: 47.5px;
    background: url(./mi_en.png) no-repeat center;
    background-size: 126px 47.5px;
}
.closeBox {
    position: absolute;
    width: 1200px;
    left: 50%;
    margin-left: -600px;
}
