@import "./../../../../../scss/pc/reset";

.Sfifth {
    width: 100%;
    height: 850px;
    background: url(./bg.png?var061501) center center no-repeat;
    background-size: cover;
}
.wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 95px 0 0;
    text-align: center;
    .text {
        transition: opacity .8s, top .8s;
        width: 600px;
        .title {
            @extend %large_new;
            padding-bottom: 10px;
        }
        .desc {
            @extend %normal_new;
            .footnote {
                position: relative;
                vertical-align: baseline;
                font-size: 8px;
                opacity: .7;
                a {
                  vertical-align: inherit;
                  color: inherit;
                }
                a:hover {
                  color: #06c;
                }
              }
        }
    }
    .imgBox {
        padding: 20px;
        width: 100%;
        box-sizing: border-box;
        .pic {
            width: 100%;
            height: 650px;
            background: url(./bg.png) center no-repeat;
            background-size: contain;
        }
    }
}

.normal {
    @extend %normal_new;
    padding-bottom: 90px;
}
.normal_en {
    @extend %normal_new;
    padding-bottom: 40px;
}
.little {
    @extend %little;
    font-weight: bold;
}
.little2 {
    @extend %little;
    padding-bottom: 90px;
    font-weight: bold;
    text-align: center;
}
.little2_en {
    @extend .little2;
    padding-bottom: 40px;
}

