@import "./../../../../scss/pc/reset";

.AllPrivacy {
    width: 100%;
    max-width: 980px;
    margin: 0 auto;
    padding: 50px 0 20px 0;
    line-height: 2;
    font-size: 16px;
    font-family: Calibri;
    p {
        padding: 15px 0;
    }
    b {
        font-weight: bold;
    }
    ul {
        padding: 5px 0;
    }
    li {
        list-style-type: disc;
        margin-left: 15px;
    }
    a {
        color: #000;
    }
    .title{
      text-align: center;
        font-size: 18pt;
    }
    .px20{
      font-size: 20px;
      font-weight: bold;
      opacity: .8;
    }
    .px18{
      font-size: 18px;
      font-weight: bold;
      opacity: .8;
    }
}