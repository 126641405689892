@import "./../../../../../scss/pc/reset";

.Gfourth {
    position: relative;
    width: 100%;
    height: 620px;
    padding: 0 0 70px 0;
    color: #000000;
    background: #fff;
}
.title {
    @extend %large;
}
.wrap {
    background: url(./G_fourth.png) no-repeat right;
    background-size: 60% auto;
    max-width: 1280px;
    width: 100%;
    margin: 0 0 0 -640px;
    position: absolute;
    left: 50%;
    height: 630px;
}
.text {
    position: absolute;
    top: 102px;
    left: 40px;
    padding-top: 100px;
    opacity: 0;
    transition: opacity .8s ease-in, top .8s;
    .ol {
        margin-top: 10px;
        padding-left: 16px;
        li {
            @extend %normal;
            font-weight: normal;
            opacity: .8;
            list-style-type:decimal;
        }
        span {
            display: block;
        }
    }
    .small {
        @extend %small;
        padding-left: 12px;
        position: relative;
        &::before{
            content: '*';
            position: absolute;
            left: 0;
            top: 2px;
        }
    }
    span {
        display: block;
    }
    .ol_safari {
        @extend .ol;
        padding-left: 22px;
    }
}
.video {
    position: absolute;
    bottom: 50px;
    left: 40px;
    padding-top: 0;
    background: #fff;
    border-radius: 15px;
    text-align: center;
    color: #424242;
    
    opacity: 0;
    transition: opacity .8s ease-in, bottom .8s;
    li {
        display: inline-block;
        margin-right: 30px;
    }
    :last-child {
        margin-right: 0;
    }
    i {
        display: block;
        margin: 30px auto 10px auto;
    }
}
.fourth_video {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    background: url(./fourth_video.gif) no-repeat center;
    background-size: cover;
}
.normal {
    @extend %normal;
}
.large {
    @extend %large;
}