@import "./../../../../../scss/pc/reset";

.Dfourth {
    width: 100%;
    color: #000;
}
.warp {
    width: 900px;
    margin: 0 auto;
    padding: 80px 0 0 0;
    @extend %clearfix;
}
.phone {
    float: left;
    width: 378px;
    height: 609px;
    background: url(./fourth.png) no-repeat center;
    background-size: 100%;
}
.imgBox {
    position: relative;
    margin: 14px 0 0 13px;
    width: 350px;
    height: 595px;
    overflow: hidden;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.img {
    position: absolute;
    top: 0;
    width: 350px;
    height: 623px;
    background:url(./img.png) no-repeat;
    background-position: top;
    background-size: 100% auto;
    transition: background-position 4s ease-in;
}
.textBox {
    float: left;
    margin: 150px 0 0 80px;
}
.title {
    @extend %large;
    padding-top: 100px;
    opacity: .7;
}
.desc {
    @extend %little;
    opacity: .6;
}
.text {
    @extend %normal;
    line-height: 1.5;
    opacity: .8;
}
.small {
    @extend %small;
}
.bar {
    position: absolute;
    top: 5px;
    left: 10px;
    width: 329px;
    height: 9px;
    background: url(./bar.png) no-repeat center;
    background-size: 100%;
    z-index: 3;
}
.top {
    position: absolute;
    top: -1px;
    width: 350px;
    height: 63px;
    background: url(./top.png) no-repeat center;
    background-size: 100%;
    z-index: 2;
    opacity: 0;
    transition: opacity 2s ease-in;
}
.topText_1 {
    position: absolute;
    top: 30px;
    width: 350px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    z-index: 4;
    opacity: 1;
}
.topText_2 {
    position: absolute;
    top: 30px;
    width: 350px;
    height: 17px;
    background: url(./topText.png) no-repeat center;
    background-size: auto 100%;
    z-index: 4;
    opacity: 0;
}