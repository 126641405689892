html {
    -webkit-tap-highlight-color: transparent;
    -moz-tap-highlight-color: transparent;
    outline: 0;
    font-size: 12px;
    font-family: 'sans-serif', '-apple-system', "Helvetica Neue", "Arial";
}
// reset default margin
body,
ul,
p,
dl,
dd,
h1,
h2,
h3 {
    margin: 0;
}
// reset default padding
ul {
    padding: 0;
}

ul,
li {
    list-style: none;
}

body {
    margin: 0 auto;
    // background: #eeeeee;
    background-color: #fff;
}

h1,
h2,
h3,
em,
b,
strong {
    font-weight: 100;
}

%clearfix{
    &:before,
    &:after {
        content: ' ';
        display: table;
        font: 0/0 a;
    }
    &:after {
        clear: both;
    }
}
sup {
    vertical-align: super;
    font-size: smaller;
}
%large {
    padding: 0 0 10px 0;
    font-size: 36px;
    line-height: 1.3;
    font-weight: bold;
    opacity: .9;
}
%little {
    // padding: 0 0 10px 0;
    font-size: 20px;
    font-weight: normal;
    // opacity: .8;
}
%normal {
    padding: 0 0 10px 0;
    font-size: 15px;
    font-weight: normal;
}
%small {
    padding: 0 0 10px 0;
    font-size: 12px;
    font-weight: normal;
}


%large_new {
    padding: 0 0 20px 0;
    font-size: 36px;
    line-height: 1.3;
    font-weight: bold;
    opacity: .9;
}
%normal_new {
    padding: 0 0 20px 0;
    font-size: 15px;
    line-height: 1.6;
    font-weight: 400;
    opacity: .8;
}
%little_new {
    padding: 0 0 10px 0;
    font-size: 20px;
    line-height: 1.4;
    font-weight: 400;
}
%small_new {
    padding: 0 0 20px 0;
    font-size: 12px;
    line-height: 1.8;
    font-weight: 400;
}