@import "\./\.\./\.\./\.\./\.\./scss/mobile/reset.module";

.privacy {
    // margin: 0 auto;
    // padding: uc(100) uc(20) 0 uc(20);
    // line-height: 1.5;
    // font-size: 16px;
    margin-top: uc(40);
    padding: uc(20);
    line-height: 1.5;
    font-size: 16px;
    font-family: Calibri;
    p {
        padding: 5px 0;
    }
    b {
        font-weight: bold;
    }
    ul {
        padding: 5px 0;
    }
    li {
        list-style-type: disc;
        margin-left: 15px;
    }
    a {
        color: #000;
    }
}