@import "./../../../../scss/pc/reset";

.box {
  .warp {
    position: relative;
    width: 757px;
    margin: 60px auto 0px auto;
    padding: 0px 0px;
    border-radius: 10px;
    border: solid 1px #cccccc;
  }
  .foot {
    background: #fff;
    width: 100%;
    bottom: 0;
    padding: 20px 0;
    font-size: 12px;
    text-align: center;
    color: #000;
    opacity: .7;
  }
  .foot a {
    font-size: 12px;
    color: #000;
    text-decoration: none;
  }
  /**home**/
  .home_title {
    display: table;
    margin: auto;
    padding: 0 40px;
    font-size: 30px;
    line-height: 110px;
    color: #000;
    opacity: .8;
  }
  .table {
    margin: auto;
    border-top: solid 1px #cccccc;
  }
  .table th {
    font-size: 16px;
    color: rgba(0, 0, 0, .8);
    padding: 20px 0 20px 30px;
    text-align: left;
  }
  .table td {
    padding: 20px 0 20px 30px;
    border-top: solid 1px #cccccc;
    font-size: 12px;
    color: rgba(0, 0, 0, .8);
    word-wrap: break-word;
    word-break: break-all;
    text-align: left;
  }
  .table th:nth-child(1),
  .table th:nth-child(4),
  .table th:nth-child(6),
  .table td:nth-child(1),
  .table td:nth-child(4),
  .table td:nth-child(6) {
    text-align: center;
  }
  .table td:nth-child(1),
  .table th:nth-child(1) {
    padding-left: 0px;
  }
  @media screen and (max-width: 1024px) {
    .warp {
        width: auto;
        /*min-height: 90%;*/
        padding: 0 0px;
        margin: 0;
        border-radius: 0px;
        border: 0;
    }
    /**home**/
    .home_title {
        padding: 0;
        line-height: 33px;
        margin: 0 20px 0px 20px;
        font-size: 14px;
        opacity: .6;
    }
    .foot {
        padding: 20px 0 20px 0;
    }
    .table {
        margin: 0;
        padding: 0 15px;
        border-top: solid 1px #cccccc;
    }
    .table th {
        font-size: 12px;
        color: rgba(0, 0, 0, .8);
        padding: 10px 0 10px 10px;
        text-align: left;
    }
    .table td {
        padding: 10px 0 10px 10px;
        border-top: solid 1px #cccccc;
        font-size: 12px;
        color: rgba(0, 0, 0, .8);
        word-wrap: break-word;
        word-break: break-all;
        text-align: left;
    }
  }
}
