@import "./../../../../../scss/pc/reset";

.outwrap {
    width: 100%;
    height: 716px;
}
.Gnineth {
    position: relative;
    width: 100%;
    height: 100%;
    background: url(./G_nineth.jpg) no-repeat center;
    background-size: cover;
    color: #000;
    .goose_a{
      color: #000;
      text-decoration: none;
    }
    .mask {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: .5;
    }
}
.Gnineth_tw {
    position: relative;
    width: 100%;
    height: 100%;
    background: url(./G_nineth_tw.jpg) no-repeat center;
    background-size: cover;
    color: #000;
    .goose_a{
      color: #000;
      text-decoration: none;
    }
    .mask {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: .5;
    }
}
.Gnineth_en {
    position: relative;
    width: 100%;
    height: 100%;
    background: url(./G_nineth_en.jpg) no-repeat center;
    background-size: cover;
    color: #000;
    .goose_a{
      color: #000;
      text-decoration: none;
    }
    .mask {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: .5;
    }
}
.wrap {
    width: 95%;
    max-width: 1220px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .goose_qingpingiot{
      position: relative;
      height: 237px;
    }
}
.text {
    position: absolute;
    max-width: 420px;
    top: 150px;
    left: 0;
    opacity: 0;
    transition: opacity .8s ease-in, top .8s;
    a {
        color: #000;
        // opacity: .8;
        text-decoration: underline;
    }
    a:hover {
        color: #000;
        text-decoration: underline;
    }
}
.info {
    margin-bottom: 35px;
    .logo {
        i {
            position: relative;
            top: 0px;
            display: inline-block;
            float: left;
            width: 65px;
            height: 65px;
            margin-right: 18px;
            background: url(./logo.png) no-repeat center;
            background-size: 100%;
        }
        span {
            color: #000;
            line-height: 65px;
        }
        // @extend %clearfix;
    }
    .download {
        margin-top: 20px;
        .ios {
            display: inline-block;
            margin-right: 20px;
            i {
                display: inline-block;
                width: 87px;
                height: 30px;
                background: url(./ios.svg) no-repeat center;
                background-size: 87px 30px;
            }
        }
        .android {
            display: inline-block;
            width: 87px;
            height: 30px;
            margin-right: 20px;
            background: url(./android.svg) no-repeat center;
            background-size: 87px 30px;
            a {
                display: inline-block;
                width: 87px;
                height: 30px;
            }
        }
        .qr_icon {
            display: inline-block;
            position: relative;
            width: 30px;
            height: 30px;
            background: url(./qr_icon.png) no-repeat center;
            background-size: 100%;
            cursor: pointer;
            .qrcode {
                position: absolute;
                bottom: 0;
                left: 0px;
                width: 0;
                height: 0;
                padding-bottom: 0px;
                padding-left: 0px;
                background: url(./qrcode.png) no-repeat center;
                background-size: 100%;
                transition: padding-bottom .5s, padding-left .5s;
                z-index: 2;
            }
            .bg {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background: #fff;
                opacity: 0;
                z-index: 1;
            }
        }
    }
}
.normal {
    margin-bottom: 13.5px;
    font-size: 20px;
    font-weight: normal;
    opacity: .8;
}
.small {
    @extend %small;
}
.large {
    @extend %large;
    font-weight: 500;
}
