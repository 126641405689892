@import ".\./\.\./\.\./\.\./\.\./scss/mobile/reset.module";

.product {
	position: relative;
    margin: 0 auto;
    padding: 0 uc(10) uc(20) uc(10);
    .ul_cn {
        li {
            // width: uc(500);
            // height: uc(150);
            margin-top: uc(10);
            border-radius: uc(5);
            a {
                display: block;
                text-decoration: none;
            }
        }
        .snow {
            background: url('./p_snow2.jpg') no-repeat center;
            background-size: 100%;
            border-radius: 5px;
            .title,.desc{
                color: #fff!important;
            }
            .desc{
                opacity: 0.6;
            }
            ul li{
                color: #fff;
                opacity: 0.6;
            }
        }
        .dany {
            background: url('./p_dany.png') no-repeat center;
            background-size: 100%;
            border-radius: 5px;
        }
        .bran {
            a {
                padding-left: uc(410/3 - 16);
            }
            // text-align: right;
            background: url('./p_bran.png') no-repeat center;
            background-size: 100%;
        }
        .goose {
            a {
                padding-left: uc(410/3 - 16);
            }
            // text-align: right;
            background: url('./p_goose.png') no-repeat center;
            background-size: 100%;
        }
        .duck2 {
            background: url('./p_duck2.png') no-repeat center;
            background-size: 100%;
        }
        .duck {
            background: url('./p_duck.png') no-repeat center;
            background-size: 100%;
        }
        .lora {
            a {
                padding-left: uc(410/3 - 16);
            }
            // text-align: right;
            background: url('./p_lora.png') no-repeat center;
            background-size: 100%;
        }
        .app {
            background: url('./p_app.png') no-repeat center;
            background-size: 100% auto;
        }
        .dove{
            a {
                padding-left: uc(410/3 - 16);
            }
            background: url('./p_dove.png') no-repeat center;
            background-size: 100%;
        }
        .sparrow{
          a {
            padding-left: uc(410/3 - 16);
        }
        background: url('./p_sparrow.png') no-repeat center;
        background-size: 100%;
        }
        .magpie {
            a {
                padding-left: uc(410/3 - 16);
            }
            background: url('./p_magpie.png') no-repeat center;
            background-size: 100%;
        }
        .hodor {
            a {
                padding-left: uc(410/3 - 16);
            }
            background: url('./p_hodor.png') no-repeat center;
            background-size: 100%;
        }

        .pheasant_co2 {
            background: url('./pheasant_co2_m_cn.png') no-repeat center;
            background-size: 100%;
        }

        .chicken {
            background: url('./chicken_m_cn.png') no-repeat center;
            background-size: 100%;

            a {
                padding-left: uc(410/3 - 16);
            }
        }

        .parrot {
            background: url('./parrot_m_cn.png') no-repeat center;
            background-size: 100%;

        }


        .frog {
            a {
                padding-left: uc(410/3 - 16);
            }

            background: url('./p_frog.png') no-repeat center;
            background-size: 100%;
        }
        .qingpingiot{
        //   a {
        //     padding-left: uc(410/3 - 16);
        // }
        .qingpingiot_content{
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .qingpingiot_left{
          width: uc(70);
          height: uc(57.39);
          margin-left: uc(70/3);
          margin-right: uc(6);
          background: url('./logo.png') no-repeat;
          background-size: uc(70) uc(57.39);
        }
        .plus_left {
          width: uc(70);
          height: uc(54.81);
          margin-left: uc(70/3);
          margin-right: uc(6);
          background: url('./p_plus.png') no-repeat;
          background-size: uc(70) uc(54.81);
        }
        .developer_left {
          width: uc(70);
          height: uc(45.56);
          margin-left: uc(70/3);
          margin-right: uc(6);
          background: url('./p_developer.png') no-repeat;
          background-size: uc(70) uc(45.56);
        }
        background: url('./p_qingpingiot_bg.png') no-repeat center;
        background-size: 100% 100%;
        }
        .title {
            @include normal;
            padding: uc(20) uc(15) 0 uc(20);
            color: #000;
            opacity: .9;
        }
        .desc {
            @include small;
            padding: uc(5) uc(15) uc(20) uc(20);
            color: #010101;
            line-height: 1.5;
            opacity: .5;
            .line {
                margin: 0 uc(5);
            }
        }
    }

    .ul_en {
        li {
            // width: uc(500);
            // height: uc(150);
            margin-top: uc(10);
            border-radius: uc(5);
            a {
                display: block;
                text-decoration: none;
                color: #000;
            }
        }
        .ul {
            margin-bottom: 0;
            padding: uc(8) uc(20) uc(20) uc(36);
            li {
                margin-top: uc(0);
                list-style-type: disc;
                opacity: .5;
            }
        }
        .snow {
            background: url('./p_snow2_en.jpg') no-repeat center;
            background-size: 100%;
            border-radius: 5px;
            .title,.desc{
                color: #fff!important;
            }
            .desc{
                opacity: 0.6;
            }
            ul li{
                color: #fff;
                opacity: 0.6;
            }
        }
        .dany {
            background: url('./p_dany_en.png') no-repeat center;
            background-size: 100%;
            border-radius: 5px;
        }
        .bran {
            a {
                padding-left: uc(410/3 - 16);
            }
            // text-align: right;
            background: url('./p_bran.png') no-repeat center;
            background-size: 100%;
        }
        .goose {
            a {
                padding-left: uc(410/3 - 16);
            }
            // text-align: right;
            background: url('./p_goose_en.jpg') no-repeat center;
            background-size: 100%;
        }
        .duck2 {
            background: url('./p_duck2_en.png') no-repeat center;
            background-size: 100%;
        }
        .duck {
            background: url('./p_duck.png') no-repeat center;
            background-size: 100%;
        }
        .lora {
            a {
                padding-left: uc(410/3 - 16);
            }
            // text-align: right;
            background: url('./p_lora_en.png') no-repeat center;
            background-size: 100%;
        }
        .app {
            background: url('./p_app.png') no-repeat center;
            background-size: 100% auto;
        }
        .dove{
            a {
                padding-left: uc(410/3 - 16);
                .desc {
                    padding-bottom: uc(10);
                }
            }
            background: url('./p_dove_en.jpg') no-repeat center;
            background-size: 100%;
        }
        .sparrow{
          a {
            padding-left: uc(410/3 - 16);
        }
        background: url('./p_sparrow_en.jpg') no-repeat center;
        background-size: 100%;
        }
        .magpie {
            a {
                padding-left: uc(410/3 - 16);
            }
            background: url('./p_magpie_en.png') no-repeat center;
            background-size: 100%;
        }
        .hodor {
            a {
                padding-left: uc(410/3 - 16);
            }
            background: url('./p_hodor_en.png') no-repeat center;
            background-size: 100%;
        }
        .pheasant_co2 {
            background: url('./pheasant_co2_m_en.png') no-repeat center;
            background-size: 100%;
        }

        .chicken {
            background: url('./chicken_m_en.png') no-repeat center;
            background-size: 100%;
            a {
                padding-left: uc(410/3 - 16);
            }
        }

        .parrot {
            background: url('./parrot_m_en.png') no-repeat center;
            background-size: 100%;

        }
        .frog {
            a {
                padding-left: uc(410/3 - 16);
            }

            background: url('./p_frog_en.png') no-repeat center;
            background-size: 100%;
        }
        .qingpingiot{
            //   a {
            //     padding-left: uc(410/3 - 16);
            // }
            .qingpingiot_content{
            display: flex;
            flex-direction: row;
            align-items: center;
            }
            .qingpingiot_left{
            width: uc(70);
            height: uc(57.39);
            margin: 0 uc(70/3);
            margin-right: uc(6);
            background: url('./logo.png') no-repeat;
            background-size: uc(70) uc(57.39);
            }
            .plus_left {
            width: uc(70);
            height: uc(54.81);
            margin: 0 uc(70/3);
            margin-right: uc(6);
            background: url('./p_plus.png') no-repeat;
            background-size: uc(70) uc(54.81);
            }
            .developer_left {
            width: uc(70);
            height: uc(45.56);
            margin: 0 uc(70/3);
            margin-right: uc(6);
            background: url('./p_developer.png') no-repeat;
            background-size: uc(70) uc(45.56);
            }
            background: url('./p_qingpingiot_bg.png') no-repeat center;
            background-size: 100% 100%;
        }
        .title {
            @include normal;
            padding: uc(20) 0 0 uc(20);
            color: #000;
            opacity: .9;
        }
        .desc {
            @include small;
            padding: uc(5) 0 uc(20) uc(15);
            color: #010101;
            line-height: 1.5;
            opacity: .5;
            .line {
                margin: 0 uc(5);
            }
        }
    }
}
