@import "./../../../../scss/pc/reset";

.bran {
	position: relative;
    width: 100%;
    height: 694px;
    background: url(./bran_img.jpg) no-repeat center bottom;
    background-size: cover;
    a {
        display: block;
        width: 100%;
        height: 100%;
        color: #000;
        text-decoration: none;
    }
}
.text {
    padding-top: 5%;
    text-align: center;
    > span {
        display: block;
    }
    .buy {
        font-size: 15px;
        line-height: 1;
        width: auto;
        display: inline-block;
        background: #2196f3;
        padding: 5px 12px;
        border-radius: 20px;
        color: #fff;
        text-decoration: none;
        margin-top: 10px;
    }
}
.little {
    @extend %little;
    opacity: .8;
}
.normal {
    @extend %normal;
    opacity: .8;
    .line {
        display: inline-block;
        margin: 0 5px;
    }
}
.large {
    @extend %large;
    opacity: .9;
}