@import "./../../../../../scss/pc/reset";

.Pnineth {
    width: 100%;
    min-width: 900px;
    height: 755.5px;
    background: url(../assets/7.jpg) no-repeat center;
    background-size: cover;
    text-align: center;
    color: #fff;
}
.wrap {
    width: 900px;
    height: 100%;
    position: relative;
    margin: 0 auto;
}
.large {
    @extend %large;
    color: #000;
    padding-top: 80px;
}
.little {
    @extend %little;
}
.normal {
    @extend %normal;
}
.line_top {
    position: absolute;
    bottom: 441px;
    right: 353px;
    width: (213px/2);
    height: (363px/2);
    background: url(./line_top.png) no-repeat right bottom;
    background-size: (213px/2) (363px/2);
    opacity: 0;
    transition: opacity .5s;
}
.line_down {
    position: absolute;
    top: 370px;
    right: 353px;
    width: (213px/2);
    height: (452px/2);
    opacity: 0;
    background: url(./line_down.png) no-repeat right top;
    background-size: (213px/2) (452px/2);
    transition: opacity .5s;
}
.p_nineth_text_top {
    // position: absolute;
    // top: 177px;
    // right: 480px;
    margin-top: 70px;
    margin-bottom: 20px;
    width: 400px;
    background: #485b6d80;
     transition: opacity .5s;
    ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 15px;
        li {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            padding: 10px 0;
            border-top: 1px solid rgba(255, 255, 255, .3);
            .column {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
            }
            .column1 {
                @extend .column;
                width: 200px;
            }
        }
        .nineth_subtitle {
            font-size: 14px;
            font-weight: 600;
            .subText {
                display: flex;
                flex-direction: row;
                justify-content: center;
                text-align: center;
                width: 100%;
                font-weight: 400;
            }
        }
    }
}
.p_nineth_text_down {
    // position: absolute;
    // top: 377px;
    // right: 480px;
    width: 400px;
    background: #485b6d80;
    transition: opacity .5s;
    ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 15px;
        li {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            padding: 11.5px 0;
            border-top: 1px solid rgba(255, 255, 255, .3);
            .column {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    span {
                        text-align: left;
                    }
                }
                .column1 {
                    @extend .column;
                    width: 200px;
                }
                .column3 {
                    @extend .column;
                    width: 120px;
                }
                .column4 {
                    @extend .column;
                    width: 140px;
                }
                .column5 {
                    @extend .column;
                    width: 140px;
                }
                .column6 {
                    @extend .column;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 140px;
                    .sub_desc {
                        opacity: .8;
                    }
                }
        }
    }
}
.p_nineth_title {
    @extend %little;
    text-align: center;
    padding: 12px 0;
    // line-height: 45px;
    font-weight: 700;
}
