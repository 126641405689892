@import "./../../../../../scss/pc/reset";

.Dfirst {
    position: relative;
    width: 100%;
    height: 720px;
    background: url(./G_first.png) no-repeat center;
    background-size: cover;
    color: #fff;
    .goose_bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        height: 118px;
        .bottom_back {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: #fff;
            opacity: .7;
            z-index: 0;
        }
        .icon_box {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            .icon_title {
                font-size: 16px;
                margin-bottom: 5px;
                color: #000;
            }
            .mi_icon {
                width: 95px;
                height: 48px;
                background:url(./MI.png) no-repeat;
                background-size: 100% 100%;
            }
            .mi_icon_en {
                width: 125px;
                height: 47px;
                background:url(./MI_en.png) no-repeat;
                background-size: 100% 100%;
            }
            .homekit_icon {
                width: 164.5px;
                height: 48px;
                background:url(./HomeKit.png) no-repeat;
                background-size: 100% 100%;
            }
        }
        .icon_box:hover {
            opacity: .5;
        }
    }
}
.text {
    width: 800px;
    height: 300px;
    margin: -210px 0 0 -400px;
    position: absolute;
    left: 50%;
    top: 50%;
    line-height: 1;
    text-align: right;
}
.videoIcon {
    display: none;
    position: relative;
    top: 5px;
    margin-right: 10px;
    // display: inline-block;
    width: 35px;
    height: 35px;
    background: url(./videoIcon.png) no-repeat center;
    background-size: 100%;
    cursor: pointer;
}
.large {
    @extend %large;
    font-size: 45px;
}
.little {
    @extend %little;
    opacity: .8;
    .line {
        margin: 0 5px;
    }
}
