@import "./../../../../../scss/pc/reset";

.Ssecond {
    .wrap {
        padding: 90px 0 0;
        background-color: #eee;
        .text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: auto;
            width: 800px;
            font-size: 12px;
            .ul, .ol {
                opacity: .8;
                li {
                    margin: 2px 0;
                    a {
                        color: #000;
                        text-decoration: underline;
                    }
                }
            }
            .ul {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                li {
                    margin-left: 10px;
                }
            }
            .ol {
                // margin-top: 20px;
                margin-bottom: 0;
                padding-left: 15px;
                li {
                    list-style-type: decimal;
                }
            }
            .ol_safari {
                @extend .ol;
                padding-left: 20px;
            }
        }
        .text_en {
            @extend .text;
            width: 950px;
        }
    }
}
