@import './../../../../../scss/pc/reset';

.Sthirteenth {
  margin: auto;
  width: 1240px;
  height: 700px;
  color: #fff;
  background: url(./27.png) center no-repeat;
  background-size: cover;
  .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0 80px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
          @extend %large_new;
          padding-bottom: 10px;
          width: 600px;
      }
      .desc {
          @extend %normal_new;
          width: 600px;

          .line {
            margin: 0 5px;
          }

      }
    }
  }
}
.Sthirteenth_en {
    @extend .Sthirteenth;
    height: 700px;
    background: url(./27.png) center no-repeat;
    background-size: cover;
}
