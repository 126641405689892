@import "./../../../../scss/pc/reset";

.box {
	// position: relative;
  //   width: 100%;
    background: #fff;
    height: 100vh;
}
.clock_all{
  height: calc(100% - 52px);
  min-height: 624px;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 50px auto;
}
.clock {
    // padding: 0 25px 50px 25px;
    text-align: center;

    // padding: 50px 0;
}
.clock_img {
    width: 111px;
    height: 115.5px;
    margin: 50px auto 24px;
    background: url(./clock.svg) no-repeat center left;
    background-size: 111px 115.5px;
}
.clock_text_1 {
    padding: 0 0;
    font-size: 20px;
    line-height: 1;
}
.clock_text_2 {
    padding: 0 0;
    margin-top: 15px;
    max-width: 780px;
    p{
      font-size: 15px;
      opacity: .6;
    }
}
.clock_card_all{
  display: flex;
  justify-content: center;
  align-items: center;
}
.clock_card {
    background: #fff;
    margin:30px 15px;
    box-shadow: 0 0 10px 0 
        rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 15px;
    width: 250px;
    height: 250px;
    .mijia_icon {
        width: 114px;
        height: 114px;
        margin: 38px auto 0;
        background: url(./left.png) no-repeat center center;
        background-size: 100%;
    }
    .app_plus_icon {
        width: 114px;
        height: 114px;
        margin: 38px auto 0;
        background: url(./right.png) no-repeat center center;
        background-size: 100%;
    }
    .clock_name {
        margin-top: 15px;
        font-size: 16px;
    }
    .clock_desc {
        padding-left: 10px;
        margin-top: 5px;
        font-size: 12px;
        opacity: .6;
    }
    .clock_link {
        font-size: 12px;
        border-radius: 21px;
        width: 51px;
        padding: 2px 0;
        text-align: center;
        margin: 10px auto 0 auto;
        border: 1px solid #5f5e5e;
        
        a {
            color: #000;
            text-decoration: none;
        }
    }
    .clock_link:active {
        opacity: .5;
    }
    .clock_nolink {
        @extend .clock_link;
        padding: 0;
        border: 0;
        opacity: .6;
    }
}
.bottom_2020q{
  font-size: 12px;
	color: #000000;
  opacity: 0.5;
  padding-bottom: 50px;
}