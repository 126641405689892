@import './../../../../scss/mobile/reset.module';

.nav {
  // height: uc(570);
  overflow-y: scroll;
  text-align: left;
  > ul {
    margin-top: uc(80/3);
  }
  li {
    display: block;
    margin: 0 uc(60/3) uc(80/3) uc(60/3);
    @include normal;
    opacity: 1;
    color: #000;
    line-height: 1;
    > a {
      color: #fff;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      text-decoration: none;
      > span {
        color: #ffffff;
        display: block;
        line-height: 1.4;
      }
    }
    > span {
      color: #ffffff;
      display: block;
    }
    i {
      display: inline-block;
      float: left;
      // margin-top: uc(-4);
      margin-right: uc(16);
      width: uc(22);
      height: uc(22);
    }
    .nav_goose {
      background: url('./nav_goose.png') no-repeat center;
      background-size: uc(22) uc(22);
    }
    .nav_dk2 {
      background: url('./nav_dk2.png') no-repeat center;
      background-size: uc(22) uc(22);
    }
    .nav_magpie {
      background: url('./nav_magpie.png') no-repeat center;
      background-size: uc(40/3) uc(22);
    }
    .nav_lora {
      background: url('./nav_lora.png') no-repeat center;
      background-size: uc(58/3) uc(58/3);
    }
    .nav_gecko {
      background: url('./nav_gecko.png') no-repeat center;
      background-size: uc(66/3) uc(66/3);
    }
    .nav_frog {
      background: url('./nav_frog.png') no-repeat center;
      background-size: uc(66/3) uc(66/3);
    }
    .nav_snow {
      background: url('./nav_snow.png') no-repeat center;
      background-size: uc(66/3) uc(66/3);
    }
    .nav_snow2 {
      background: url('./nav_snow2.png?var=111') no-repeat center;
      background-size: uc(66/3) uc(66/3);
    }
    .nav_dany {
      background: url('./nav_dany.png') no-repeat center;
      background-size: uc(66/3) uc(66/3);
    }
    .nav_hodor {
      background: url('./nav_hodor.png') no-repeat center;
      background-size: uc(56/3) uc(61/3);
    }
    .nav_parrot {
      background: url('./nav_parrot.png') no-repeat center;
      background-size: uc(66/3) uc(66/3);
    }
    .nav_bran {
      margin-top: uc(-4);
      background: url('./nav_bran.png') no-repeat center;
      background-size: uc(22) uc(39/3);
    }
    .nav_app {
      // width: uc(66/3);
      // height: uc(26/3);
      background: url('./plus.png') no-repeat center;
      background-size: uc(66/3) auto;
    }
    .nav_iot {
      background: url('./nav_iot_1.png') no-repeat center;
      background-size: uc(22) auto;
    }
    .nav_developer {
      background: url('./nav_developer.png') no-repeat center;
      background-size: uc(57/3) uc(52/3);
    }
    .nav_store {
      background: url('./nav_store.png') no-repeat center;
      background-size: uc(48/3) uc(58/3);
    }
    .nav_phone {
      // background: url('./nav_phone.png') no-repeat center;
      background: url('./connect_us.png') no-repeat center;
      background-size: uc(62/3) uc(62/3);
    }
    .nav_dove {
      background: url('./nav_dove.png') no-repeat center;
      background-size: uc(58/3) uc(58/3);
    }
    .nav_chicken {
      background: url('./nav_chicken.png') no-repeat center;
      background-size: uc(60/3) uc(60/3);
    }
    .nav_sparrow {
      background: url('./nav_sparrow.png') no-repeat center;
      background-size: uc(48/3) uc(64/3);
    }
  }
  .sub:active,
  .line:active,
  .line_s:active {
    opacity: 0.5;
  }
  .title {
    font-size: 12px;
    @include small;
    opacity: 0.6;
  }
  .line {
    padding-bottom: uc(80/3);
    border-bottom: 1px solid rgba(238, 238, 238, 0.1);
  }
  .line_s {
    padding-bottom: uc(80/3);
    border-bottom: 1px solid rgba(238, 238, 238, 0.1);
  }
}

.copy {
  margin: uc(100/3) 0 uc(20) 0;
  text-align: center;
  span,
  a {
    display: block;
    margin-bottom: uc(10);
    font-size: 12px;
    color: #999;
    line-height: 1;
  }
}
