@import "./../../../../../scss/pc/reset";

.Geighth {
    position: relative;
    width: 100%;
    padding-bottom: 100px;
    
    background: #353535;
    color: #fff;
}
.text {
    width: 100%;
    top: 0;
    left: 0;
    padding: 100px 0 55px 0;
    z-index: 2;
    text-align: center;
    span {
        display: block;
    }
}
.img {
    width: 309px;
    height: 309px;
    margin: 0 auto;
    background: url(./G_eighth.png) no-repeat center;
    background-size: 100%;
    opacity: 0;
    transition: opacity .8s;
    transition: opacity .8s;
}
.small {
    @extend %small;
}
.large {
    @extend %large;
}