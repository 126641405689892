@import './../../../../../scss/pc/reset';

.Ssixth {
  color: #000;
  background-color: #f5f5f5;
  width: 100%;
  padding-top: 100px;
}
.wrap {
  width: 100%;
  text-align: center;
}
.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 700px;
  transition: opacity 0.8s, top 0.8s;
  .title {
    @extend %large_new;
    padding-bottom: 10px;
    .footnote {
      position: relative;
      vertical-align: baseline;
      font-size: 24px;
      opacity: .7;
      a {
        vertical-align: inherit;
        color: inherit;
      }
      a:hover {
        color: #06c;
      }
    }
  }
  .desc {
    @extend %normal_new;
  }
}

.imgBox {
  padding: 20px;
  padding-top: 0;
  width: 100%;
  box-sizing: border-box;
  .pic {
    width: 100%;
    height: 650px;
    background: url(./bg.png) center no-repeat;
    background-size: contain;
  }
}