@import './../../../../../scss/pc/reset';

.Sseventh {
  width: 100%;
  color: #000;
  width: 100%;
  background-color: #edece8;
  .wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 20px;
    width: 1240px;
    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      text-align: left;
      padding-left: 100px;
      width: 50%;
      transition: opacity 0.8s, top 0.8s;
      box-sizing: border-box;
      .title {
        @extend %large_new;
        padding-bottom: 10px;
        padding-right: 100px;
        .footnote {
          position: relative;
          vertical-align: baseline;
          font-size: 24px;
          opacity: .7;
          a {
            vertical-align: inherit;
            color: inherit;
          }
          a:hover {
            color: #06c;
          }
        }
      }
      .desc {
        @extend %normal_new;
        padding-right: 100px;
      }

      .level {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .level_item {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin-right: 50px;
          margin-top: 20px;

          .item_icon1, .item_icon2, .item_icon3 {
            margin-bottom: 20px;
            width: 50px;
            height: 50px;
          }
          .item_icon1 {
            background: url(./12.png) center no-repeat;
            background-size: 50px 50px;
          }
          .item_icon2 {
            background: url(./13.png) center no-repeat;
            background-size: 50px 50px;
          }
          .item_icon3 {
            background: url(./14.png) center no-repeat;
            background-size: 50px 50px;
          }

        }

      }
      
      
    }
  }
}

.imgBox {
  width: 50%;
  .img {
    width: 100%;
    height: 660px;
    background: url(./15.png) center no-repeat;
    background-size: contain;
  }
}
