@import "./../../../../../scss/pc/reset";

.Gseventh {
    position: relative;
	width: 100%;
    padding-bottom: 120px;
    
    background: #fff;
}
.text {
    width: 100%;
    top: 0;
    left: 0;
    padding: 120px 0 100px 0;
    z-index: 2;
    text-align: center;
}
.img {
    width: 438px;
    height: 133px;
    margin: 0 auto;
    background: url(./G_seventh.png) no-repeat center;
    background-size: 100%;
    opacity: 0;
    transition: opacity .8s;
}
.line {
    margin: 0 5px;
}
.little {
    @extend %little;
    font-weight: normal;
    opacity: .8;
}
.large {
    display: block;
    @extend %large;
}