@import './../../../../../scss/pc/reset';

.Peighth {
  width: 100%;
  height: 650px;
  text-align: center;
  background-color: #323a41;
  color: #fff;
}
.large {
  @extend %large;
  opacity: 0.8;
}
.text {
  padding-top: 100px;
  position: relative;
  top: 50px;
  opacity: 0;
  transition: opacity 0.8s, top 0.8s;
  line-height: 1.5;
}
.p_eighth_img {
  width: (398px/2);
  height: (497px/2);
  margin: 70px auto 50px auto;
  background: url(./P_eighth_email_new.png) no-repeat center;
  background-size: cover;
}
.p_eighth_email {
  @extend %normal;
  opacity: 0.6;
  a {
    color: #fff;
    text-decoration: underline;
  }
}
