@import "./../../../../../scss/pc/reset";

.Deleventh {
	position: relative;
    width: 100%;
    background: #202020;
    padding-bottom: 80px;
    text-align: center;
    color: #fff;
}
.item {
    display: inline-block;
    margin: 30px 25px;
    width: 250px;

}
.img_1 {
    display: block;
    width: 150px;
    height: 150px;
    margin: 0 auto 20px auto;
    background: url(./app1.png) no-repeat center;
    background-size: 100%;
}
.img_2 {
    @extend .img_1;
    background: url(./app2.png) no-repeat center;
    background-size: 100%;
}
.desc {
    padding-top: 100px;
    @extend %little;
}
.text {
    @extend %small;
    line-height: 1.5;
    opacity: .8;
}
