@import './../../../../../scss/pc/reset';

.Stenth {
  width: 100%;
  color: #000;
  height: 700px;
  background: url(./19.jpg) center no-repeat;
  background-size: auto 700px;
  .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 85px;
    .text {
      .title {
        @extend %large_new;
        padding-bottom: 10px;
        margin: auto;
        width: 550px;
      }
      .desc {
        @extend %normal_new;
        width: 550px;
      }
    }
  }
}
