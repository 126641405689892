@import "\./\.\./\.\./\.\./\.\./scss/mobile/reset.module";

.download {
    width: 100%;
    font-family: "PingFangSC-Regular";
    background: #fff;
}
.head {
    padding: uc(25) 0 0 0;
    width: 100%;
    z-index: 1;
}
.logo {
    text-align: center;
    i {
        display: inline-block;
        width: uc(83);
        height: uc(14.5);
        background: url(./top_logo_new.png) no-repeat center;
        background-size: 100% 100%;
    }
}
.content {
    margin: uc(25);
    padding: uc(25) uc(22);
    line-height: 1;
    box-shadow: 0 0 uc(30) 0 rgba(0, 0, 0, 0.1);
    border-radius: uc(12);
    .desc {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: uc(25);
        .big_logo {
            display: inline-block;
            position: relative;
            width: uc(36);
            height: uc(36);
            margin-right: uc(10);
            background: url(./logo.png) no-repeat center;
            background-size: 100% 100%;
        }
        @extend %clearfix;
    }
}
.device {
    .goose_img {
        width: uc(120);
        height: uc(85);
        margin: 0 auto;
        background: url(./Goose.png) no-repeat center;
        background-size: 100% 100%;
    }
    .snow_img {
        width: uc(121);
        height: uc(90);
        margin: 0 auto;
        background: url(./Snow.png) no-repeat center;
        background-size: 100% 100%;
    }
    .lora_img {
        width: uc(121);
        height: uc(90);
        margin: 0 auto;
        background: url(./Lora.png) no-repeat center;
        background-size: 100% 100%;
    }
    .name {
        margin: uc(15) 0 uc(10) 0;
        font-size: 16px;
        text-align: center;
    }
    .desc {
        border-bottom: 0;
        padding-bottom: 0;
        font-size: 12px;
        opacity: .6;
        line-height: uc(22);
        text-align: center;
    }
}
.item {
    margin-top: uc(25);
    @extend %clearfix;
    .aqi_img {
        display: inline-block;
        margin-right: uc(22);
        width: uc(40);
        height: uc(40);
        background: url(./AQI.png) no-repeat center;
        background-size: uc(36);
    }
    .weather_img {
        display: inline-block;
        margin-right: uc(22);
        width: uc(40);
        height: uc(40);
        background: url(./weather.png) no-repeat center;
        background-size: 100% 100%;
    }
}
.text {
    position: relative;
    top: uc(-3);
    display: inline-block;
    color: #000;
    text-align: left;
    .name {
        font-size: 16px;
    }
    .slogan {
        margin-top: uc(10);
        font-size: 12px;
        opacity: .7;
    }
    .title {
        font-size: 15px;
    }
    .detail {
        margin-top: uc(10);
        font-size: 12px;
        opacity: .5;
    }
}
.link {
    margin-top: uc(25);
    text-align: center;
    .link_download {
        float: left;
        text-decoration: underline;
        opacity: .6;
        margin: 0 uc(7);
        cursor: pointer;
    }
    .link_more {
        float: right;
    }
    a {
        margin: 0 uc(7);
        font-size: 12px;
        color: #000;
        opacity: .6;
    }
}
.foot {
    width: 100%;
    margin-bottom: uc(25);
    font-size: 13px;
    color: rgba(0, 0, 0, .5);
    text-align: center;
}
.hide {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #ffffff;
    text-align: center;
    .arrow {
        position: absolute;
        top: 0;
        right: 0;
        width: uc(192*0.7);
        height: uc(115*0.7);
        background: url(./arrow.png) no-repeat center;
        background-size: 100% 100%;
    }
    .hideText {
        margin-top: uc(40);
        font-size: 16px;
        li {
            line-height: uc(40);
            span {
                color: #007aff;
            }
        }
    }
}
.iosShow {
    @extend .hide;
    display: block;
    .phone {
        position: relative;
        top: uc(30);
        left: uc(25);
        width: uc(243);
        height: uc(365);
        margin: 0 auto;
        background: url(./ios.png) no-repeat center;
        background-size: 100% 100%;
    }
}
.androidShow {
    @extend .hide;
    display: block;
    .phone {
        position: relative;
        top: uc(30);
        left: uc(25);
        width: uc(243);
        height: uc(365);
        margin: 0 auto;
        background: url(./android_pnone.png) no-repeat center;
        background-size: 100% 100%;
    }
}
.line {
    display: inline-block;
    margin: 0 5px;
}