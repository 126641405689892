body {
    background: #fff;
}
.warp {
    position: relative;
    width: 757px;
    margin: 60px auto 0px auto;
    padding: 0px 0px;
    border-radius: 10px;
    background: #fff;
    border: solid 1px #cccccc;
    table {
        margin: 0 40px;
        border-top: solid 1px #cccccc;
        th {
            font-size: 16px;
            color: rgba(0, 0, 0, .8);
            padding: 20px 0 20px 30px;
            text-align: left;
        }
        td {
            padding: 20px 0 20px 30px;
            border-top: solid 1px #cccccc;
            font-size: 12px;
            color: rgba(0, 0, 0, .8);
            word-wrap: break-word;
            word-break: break-all;
            text-align: left;
        }
        th:nth-child(1),
        th:nth-child(4),
        th:nth-child(6),
        td:nth-child(1),
        td:nth-child(4),
        td:nth-child(6) {
            text-align: center;
        }
        td:nth-child(1),
        th:nth-child(1) {
            padding-left: 0px;
        }
    }
    .home_title {
        padding: 0 40px;
        font-size: 30px;
        line-height: 110px;
        color: #000;
        opacity: .8;
    }
}

.foot {
    width: 100%;
    bottom: 0;
    padding: 20px 0;
    font-size: 12px;
    text-align: center;
    color: #000;
    opacity: .7;
}
.foot a {
    font-size: 12px;
    color: #000;
    text-decoration: none;
}
