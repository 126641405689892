@import "./../../../../../scss/pc/reset";

.Stwelfth {
    width: 100%;
    color: #000;
    background-color: #fff;

    .wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        padding: 80px 0 10px;
        width: 580px;
        text-align: center;

        .title {
            @extend %large_new;
            padding-bottom: 35px;
        }

        .imgBox {

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 1280px;

            .img1, .img2 {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                margin: 10px;
                width: 610px;
                height: 550px;
                .desc {
                    @extend %little_new;
                    padding-bottom: 50px;
                    opacity: .8;
                    a {
                        color: #fff;
                        text-decoration: underline;
                    }
                }
            }
            .img1 {
                background: url(./25.png) center no-repeat;
                background-size: cover;
            }
            .img2 {
                background: url(./26.png) center no-repeat;
                background-size: cover;
            }
            
        }

    }
}
