@import "./../../../../../scss/pc/reset";

.Pfourth {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    background-color: #fff;
    .box {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 610px;
        margin: 0 10px;
        color: #fff;
        border-radius: 10px;
        .leftImg {
            width: 610px;
            height: 354px;
            background: url(../assets/4.jpg) no-repeat center;
            background-size: 610px 354px;
        }
        .rightImg {
            width: 610px;
            height: 354px;
            background: url(../assets/5.jpg) no-repeat center;
            background-size: 610px 354px;
        }
        .content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0 70px;
            height: 100px;
            text-align: center;
            line-height: 1.2;
            vertical-align: middle;
            background-color: #8b96a2;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
    
}
