@import "./../../../../scss/pc/reset";

.Bprivacy {
    width: 100%;
    max-width: 980px;
    margin: 0 auto;
    padding: 20px 0 20px 0;
    line-height: 1.5;
    font-size: 16px;
    font-family: Calibri;
    p {
        padding: 5px 0;
    }
    b {
        font-weight: bold;
    }
    ul {
        padding: 5px 0;
    }
    li {
        list-style-type: disc;
        margin-left: 15px;
    }
    a {
        color: #000;
    }
}