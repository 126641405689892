@import "./../../../../../scss/pc/reset";
.specifications {
  position: relative;
  width: 100%;
  background: #fff;
  padding: 50px 0 30px 0;
  color: #333;
}
.Gparam {
  display: table;
  width: 900px;
  margin: 0 auto;
}
.row {
  display: table-row;
  border-bottom: 1px solid #eeeeee;
  @extend %clearfix;
}
.name {
  display: table-cell;
  width: 150px;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #eeeeee;
  @extend %little;
  font-weight: bold;
}
.cell {
  display: table-cell;
  padding: 50px 0px 30px 100px;
  border-bottom: 1px solid #eeeeee;
  @extend %normal;
  font-weight: normal;
  line-height: 1;
  .sizeImg {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 80px;
      height: 266px;
      .top_side {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          .left_left {
              .left_img {
                  width: 210px;
                  height: 220px;
                  background: url('./front.png') no-repeat;
                  background-size: 100%;
              }
              .left_img_en {
                @extend .left_img;
                background: url('./front_en.png') no-repeat;
                background-size: 100%;
            }
              .left_bottom {
                  margin-top: 30px;
                  width: 210px;
                  height: 4px;
                  border: 1px solid #7f7f7f;
                  border-top: none;
              }
              .bottom_num {
                  padding-top: 10px;
                  width: 210px;
                  text-align: center;
                  opacity: .6;
              }
          }
          .left_right {
              margin-left: 20px;
              width: 4px;
              height: 220px;
              border: 1px solid #7f7f7f;
              border-left: none;
          }
          .right_num {
              position: absolute;
              right: 63px;
              height: 220px;
              // line-height: 220px;
              transform: rotateZ(90deg);
              opacity: .6;
          }
      }
      .top_top {
          margin-left: 100px;
          .top_img {
              width: 110px;
              height: 220px;
              background: url('./side.png') no-repeat;
              background-size: 100%;
          }
          .top_img_en {
            @extend .top_img;
            background: url('./side_en.png') no-repeat;
            background-size: 100%;
        }
          .top_bottom {
              margin-top: 30px;
              width: 110px;
              height: 4px;
              border: 1px solid #7f7f7f;
              border-top: none;
          }
          .bottom_num {
              padding-top: 10px;
              width: 110px;
              text-align: center;
              opacity: .6;
          }
      }
  }
  ul {
    .type {
      display: inline-block;
      width: 180px;
      opacity: .6;
      font-weight: normal;
    }
    li {
      margin-bottom: 24px;
    }
  }
  p{
    font-size: 12px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.5;
    font-weight: normal;
  }
}
// .sizeImg {
//   width: 428px;
//   height: 280px!important;
//   margin-bottom: 50px;
//   background: none!important;
// }
.pack {

  display: table-row;
  @extend %normal;
  .name,
  .cell {
    border-bottom: 0;
  }
  @extend %clearfix;
  ul {
    padding: 0px 0 0;
    text-align: center;
    @extend %clearfix;
  }
  li {
    float: left;
    display: inline-block;
    margin: 8px 40px 8px 0;
    i {
      margin-bottom: 15px;
    }
    span {
      line-height: 1.6;
    }
  }
  .packImg{
    p{
      color: #000;
      opacity: 1;
      font-size: 15px;
    }
  }
}
.packImg {
  width: 120px;
  height: 149px;
  //background: url(./bottom.png) no-repeat center;
  background-size: 100%;
  float: left;
  text-align: center;
  margin-right: 30px;
  p{
    margin-top: 10px;
    color: #333;
  }
}
