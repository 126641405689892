.container {
    width: 1200px;
    height: 488px;
    margin: 0 auto;
    // background-color: greenyellow;
    ul {
        display: flex;
        justify-content: space-between;
    }
    li {
        width: 373px;
        height: 488px;
        background: #ffffff;
        border-radius: 15px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
}
.text {
    overflow: hidden;
    margin: 40px;
}
//-----
.one_img {
    flex-grow: 1;
    background: url(../../../../../../static/img/pc/air-monitor-g2/overview/P3-1CN.jpg) no-repeat center;
    background-size: cover;
}
.one_img_en {
    flex-grow: 1;
    background: url(../../../../../../static/img/pc/air-monitor-g2/overview/P3-1EN.jpg) no-repeat center;
    background-size: cover;
}
.one_img_tw {
    flex-grow: 1;
    background: url(../../../../../../static/img/pc/air-monitor-g2/overview/P3-1TW.jpg) no-repeat center;
    background-size: cover;
}
.two_img {
    flex-grow: 1;
    background: url(../../../../../../static/img/pc/air-monitor-g2/overview/P3-2CN.jpg) no-repeat center;
    background-size: cover;
}
.two_img_en {
    flex-grow: 1;
    background: url(../../../../../../static/img/pc/air-monitor-g2/overview/P3-2EN.jpg) no-repeat center;
    background-size: cover;
}
.two_img_tw {
    flex-grow: 1;
    background: url(../../../../../../static/img/pc/air-monitor-g2/overview/P3-2TW.jpg) no-repeat center;
    background-size: cover;
}

.third_img {
    flex-grow: 1;
    background: url(../../../../../../static/img/pc/air-monitor-g2/overview/P3-3TW.jpg) no-repeat center;
    background-size: cover;
}
.third_img_en {
    flex-grow: 1;
    background: url(../../../../../../static/img/pc/air-monitor-g2/overview/P3-3EN.jpg) no-repeat center;
    background-size: cover;
}
// }
