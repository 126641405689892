@import "./../../../../../scss/pc/reset";

.Dfifth {
    width: 100%;
    height: 850px;
    background: url(./fifth.jpg) no-repeat center;
    background-size: cover;
    text-align: center;
    color: #000;
}
.title {
    @extend %large;
    padding-top: 100px;
    opacity: .7;
}
.desc {
    @extend %little;
    opacity: .6;
}
.text {
    @extend %normal;
    line-height: 1.5;
    opacity: .8;
}
.small {
    @extend %small;
    line-height: 1.5;
    opacity: .8;
}
