@import '~antd/dist/antd.css';
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 覆写 antd */
*,
*::before,
*::after {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

/* Pheasant 页面 */
.ant-select {
  width: 145px;
  color: #fff;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #4a4a4a;
  border: 1px solid #858585;
  border-radius: 5px;
  height: 48px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 48px;
}

.ant-select-arrow {
  color: #9e9e9e;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #1294f7;
  background-color: #fff;
}

.ant-select-item {
  min-height: 48px;
  line-height: 39px;
}

.ant-select-dropdown {
  border-radius: 5px;
}

.ant-radio-wrapper {
  margin-left: 10px;
}

.ant-radio-inner {
  width: 20px;
  height: 20px;
  background: url('./static/img/circle.png') center no-repeat;
  background-size: 100%;
  border-style: none;
}
.ant-radio-checked .ant-radio-inner {
  width: 20px;
  height: 20px;
  background: url('./static/img/right.png') center no-repeat;
  background-size: 100%;
}

/* Frog 覆写页面 */
.frog .ant-select {
  width: 145px;
  color: #000;
}
.frog .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 48px;
}
.frog
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector:hover {
  border-color: #1294f7;
}

.frog .ant-select-single .ant-select-selector .ant-select-selection-item,
.frog
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 48px;
}

.frog .ant-select-arrow {
  color: #9e9e9e;
}

.frog .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #1294f7;
  background-color: #fff;
}

.frog .ant-select-item {
  min-height: 48px;
  line-height: 39px;
}

.frog .ant-select-dropdown {
  border-radius: 5px;
}

.frog .ant-radio-wrapper {
  margin-left: 10px;
}

.frog .ant-radio-inner {
  width: 20px;
  height: 20px;
  background: url('./static/img/circle.png') center no-repeat;
  background-size: 100%;
  border-style: none;
}
.frog .ant-radio-checked .ant-radio-inner {
  width: 20px;
  height: 20px;
  background: url('./static/img/right.png') center no-repeat;
  background-size: 100%;
}
