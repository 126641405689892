@import "./../../../../../scss/pc/reset";

.Seleventh {
    width: 100%;
    background-color: #fff;
    color: #000;
    .wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        padding-top: 20px;
        .row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 20px;
            width: 1240px;
            
            .img1, .img2 {
                width: 620px;
                height: 550px;
            }
            .img1 {
                background: url(./20.png) center no-repeat;
                background-size: cover;
            }
            .img2 {
                background: url(./21.png) center no-repeat;
                background-size: cover;
            }

            .text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 620px;
                height: 550px;
                box-sizing: border-box;
                background-color: #eeece8;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                .title {
                    @extend %large_new;
                    text-align: center;
                }
            }

        }
    }
}