@import "./../../../../scss/pc/reset";

.box {
	position: relative;
    width: 100%;
    background: #fff;

}
.store {
    ul {
        max-width: 764px;
        width: auto;
        margin: 0 auto;
        padding: 60px 0 60px 0;
        text-align: center;
        @extend %clearfix;
        li {
            float: left;
            border: 1px #eeeeee solid;
            border-radius: 2px;
            width: 350px;
            height: 490px;
            margin: 15px;
            line-height: 1;
        }
        > :hover {
            // top: -5px;
            border: 1px #ffffff solid;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
            // transition: top .5s;
        }
        .snow {
            background: url(./store_snow.jpg) no-repeat center 100px;
            background-size: 100% auto;
        }
        .bran {
            background: url(./store_bran.jpg) no-repeat center 100px;
            background-size: 100% auto;
        }
        .goose {
            background: url(./store_goose.jpg) no-repeat center 100px;
            background-size: 100% auto;
        }
        .lora {
            background: url(./store_lora.jpg) no-repeat center 100px;
            background-size: 100% auto;
        }
        .duck {
            background: url(./store_duck.jpg) no-repeat center 100px;
            background-size: 100% auto;
        }
        .pheasant {
            display: none;
            background: url(./store_pheasant.jpg) no-repeat center 100px;
            background-size: 100% auto;
        }
        .lipstick {
            background: url(./store_lipstick.jpg) no-repeat center 100px;
            background-size: 100% auto;
        }
        .title {
            margin-top: 350px;
            font-size: 20px;
            color: #424242;
        }
        .price {
            margin: 15px 0;
            font-size: 20px;
            color: #2196f3;
            span {
                font-size: 12px;
            }
        }
        .btu {
            display: inline-block;
            background: #2196f3;
            padding: 4px 10px;
            border-radius: 15px;
            a {
                font-size: 15px;
                color: #fff;
                text-decoration: none;
            }
            span {
                font-size: 15px;
                color: #fff;
            }
        }
        .btuNoBuy {
            @extend .btu;
            opacity: .5;
        }
    }
}
.little {
    @extend %little;
    opacity: .8;
}
.normal {
    @extend %normal;
    opacity: .8;
}
.large {
    @extend %large;
    opacity: .9;
}