html {
    -webkit-tap-highlight-color: transparent;
    -moz-tap-highlight-color: transparent;
    outline: 0;
    font-family: 'sans-serif', '-apple-system', "Helvetica Neue", "Arial";
    font-size: 12px;
}
// reset default margin
body,
ul,
p,
dl,
dd,
h1,
h2,
h3 {
    margin: 0;
}

// reset default padding
ul {
    padding: 0;
}

ul,
li {
    list-style: none;
}

body {
    margin: 0 auto;
}

h1,
h2,
h3,
em,
b,
strong {
    font-weight: 100;
}

%clearfix{
    &:before,
    &:after {
        content: ' ';
        display: table;
        font: 0/0 a;
    }
    &:after {
        clear: both;
    }
}
sup {
    vertical-align: super;
    font-size: smaller;
}
@mixin large($weight: bold) {
    padding: 0 0 uc(5) 0;
    font-size: uc(24);
    font-weight: $weight;
    line-height: 1.3;
    opacity: .9;
}
@mixin size_18($weight: bold) {
    padding: 0 0 uc(5) 0;
    font-size: uc(18);
    font-weight: $weight;
    line-height: 1.3;
    opacity: .9;
}
@mixin little($weight: normal) {
    padding: 0 0 uc(5) 0;
    font-size: uc(16);
    font-weight: $weight;
    line-height: 1.3;
    opacity: .7;
}
@mixin normal($weight: normal) {
    padding: 0 0 uc(5) 0;
    font-size: uc(14);
    font-weight: $weight;
    line-height: 1.4;
    opacity: .6;
}
@mixin small($weight: normal) {
    padding: 0 0 uc(5) 0;
    font-size: uc(12);
    font-weight: $weight;
    line-height: 1.5;
    opacity: .8;
}
$default_width: 375px;

/* *
 * @brief unit conversion
 * params  number
 * @return em or vm/vh
 * */
@function uc($n: 0) {
    @return ($n * 1px) / $default_width * 1rem * 10;
}
