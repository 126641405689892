@import './../../../../scss/pc/reset';

.nav {
  position: relative;
  right: 0;
  width: 100%;
}
.navFixed {
  position: fixed;
  top: 0;
  width: 100%;
}
.first_sub {
  position: relative;
  width: 100%;
  // max-width: 1200px;
  // margin: 0 auto;
  line-height: 50px;
  z-index: 9;
  text-align: center;
  background: rgba(255, 255, 255, 0.95);
  > :before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    // bottom: 0;
    height: 1px;
    width: 100%;
    background: #e5e5e5;
    // z-index: 10;
    margin-top: 50px;
  }
  @extend %clearfix;
  .line {
    display: none;
    position: absolute;
    left: 0;
    bottom: -7px;
    height: 2px;
    width: 100%;
    background: #b3b3b3;
    border-radius: 1px;
  }
}
.items {
  width: 100%;
  .logo {
    position: relative;
    top: 6px;
    width: 101px;
    height: 18px;
    z-index: 99;
    a {
      display: block;
      width: 101px;
      height: 18px;
      background-image: url('./head_logo.png');
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .active {
      // background-color: #e5e5e5;
    }
  }
  .logo:hover a {
    // background-color: #e5e5e5;
  }
  > li {
    display: inline-block;
    margin: 0 30px;
    font-size: 15px;
    color: #424242;
    cursor: pointer;
    > span a {
      position: relative;
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
    > span {
      position: relative;
      color: #000;
      text-decoration: none;
      opacity: 0.8;
      cursor: pointer;
    }
    > .active {
      .line {
        display: block;
      }
    }
  }
  > :hover .line {
    display: block;
  }
  > :hover .store {
    .line {
      display: block;
      background: #2196f3;
    }
  }
  > .active {
    .line {
      display: block;
    }
    .store {
      .line {
        display: block;
        background: #2196f3;
      }
    }
  }
  
  .shopArrow {
    position: relative;
    top: 3px;
    margin-left: -18px;
    display: inline-block;
    width: 8px;
    height: 14px;
    background: url(./G_more_blank.png) center no-repeat;
    background-size: 5.33px 9.33px;
    transform: rotateZ(90deg);
    cursor: pointer;
    .wrapMall {
        display: none;
        position: absolute;
        top: -50px;
        left: 46px;
        transform: rotateZ(-90deg);
    
        .mallBox {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 130px;
          list-style: none;
          background-color: #fff;
          font-size: 15px;
          border-radius: 8px;
          box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);
    
          .mallItem {
            width: 100%;
            padding-left: 17px;
            color: #000;
            cursor: pointer;
            text-align: left;
    
            span {
              width: 100%;
              margin-left: 0;
              opacity: 1;
              display: inline-block;
              color: #000;
              text-decoration: none;
            }
          }
    
    
          .mallItem:hover {
            span {
              opacity: .5;
            }
          }
        }
      }
  }
    // > :hover .wrapMall {
    //   display: inline-block;
    // }

        
}

.buy {
  display: none;
  position: absolute;
  right: 15px;
  top: 10px;
  height: 27px;
  line-height: 27px;
  padding: 0 15px;
  background: #424242;
  border-radius: 27px;
  a {
    color: #fff;
    text-decoration: none;
  }
  a:hover {
    color: #fff;
  }
}

.airHide,
.tempHumHide {
  position: absolute;
  height: 0;
  left: 0;
  top: 50px;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  text-align: center;
  overflow: hidden;
  z-index: 9;
  > :before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 0px;
    height: 1px;
    width: 100%;
    background: #e5e5e5;
    z-index: 10;
  }
  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .limit_width {
      width: 200px;
    }
    li {
      padding: 25px 15px 0px 15px;
      opacity: 0.5;
      box-sizing: border-box;

      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
          display: block;
          margin-top: 10px;
          height: 30px;
          line-height: 18px;
        }
      }
  
      .goose {
        display: block;
        float: none;
        width: 40px;
        height: 40px;
        margin: 0 auto;
        background: url(./Goose.png) no-repeat center;
        background-size: 100%;
      }
  
      .duck2 {
        display: block;
        float: none;
        width: 40px;
        height: 40px;
        margin: 0 auto;
        background: url(./duck2.png) no-repeat center;
        background-size: 100%;
      }
  
      .lora {
        display: block;
        float: none;
        width: 40px;
        height: 40px;
        margin: 0 auto;
        background: url(./pheasantCO2.png) no-repeat center;
        background-size: 100%;
      }
  
      .gecko {
        display: block;
        float: none;
        width: 40px;
        height: 40px;
        margin: 0 auto;
        background: url(./gecko.png) no-repeat center;
        background-size: 100%;
      }
  
      .frog {
        display: block;
        float: none;
        width: 40px;
        height: 40px;
        margin: 0 auto;
        background: url(./frog.png) no-repeat center;
        background-size: 100%;
      }
  
      .duck {
        display: block;
        float: none;
        width: 23px;
        height: 40px;
        margin: 0 auto;
        background: url(./magpie-icon-selected.png) no-repeat center;
        background-size: 23px 40px;
      }
  
      .snow {
        display: block;
        float: none;
        width: 40px;
        height: 40px;
        margin: 0 auto;
        background: url(./snow.png) no-repeat center;
        background-size: 40px 40px;
      }
      .snow2 {
        display: block;
        float: none;
        width: 40px;
        height: 40px;
        margin: 0 auto;
        background: url(./snow2.png) no-repeat center;
        background-size: 40px 40px;
      }
  
      .dany {
        display: block;
        float: none;
        width: 40px;
        height: 40px;
        margin: 0 auto;
        background: url(./dany.png) no-repeat center;
        background-size: 40px 40px;
      }
  
      .hodor {
        display: block;
        float: none;
        width: 40px;
        height: 40px;
        margin: 0 auto;
        background: url(./hodor.png) no-repeat center;
        background-size: 40px 40px;
      }
  
      .parrot {
        display: block;
        float: none;
        width: 40px;
        height: 40px;
        margin: 0 auto;
        background: url(./parrot.png) no-repeat center;
        background-size: 40px 40px;
      }
  
      .dove {
        display: block;
        float: none;
        width: 40px;
        height: 40px;
        margin: 0 auto;
        background: url(./dove.png) no-repeat center;
        background-size: 40px 40px;
      }
  
      .chicken {
        display: block;
        float: none;
        width: 40px;
        height: 40px;
        margin: 0 auto;
        background: url(./chicken.png) no-repeat center;
        background-size: 40px 40px;
      }
  
      .bran {
        display: block;
        float: none;
        width: 55px;
        height: 40px;
        margin: 0 auto;
        background: url(./bran.png) no-repeat center;
        background-size: 55px 31px;
      }
  
      a {
        display: block;
        color: #000;
        font-size: 15px;
        text-decoration: none;
      }
    }
  
    :hover {
      opacity: 1;
    }
  
    .active {
      position: relative;
      opacity: 1;
    }
  
    .hide {
      display: none;
    }
  }
  
}

.tempHumHide_en {
  @extend .tempHumHide;
  ul {
    .limit_width {
      width: 220px;
    }
  }
}

.airHide_en {
  @extend .airHide;
  ul {
    .limit_width {
      width: 220px;
    }
  }
}

.tempHum:hover .tempHumHide, .airs:hover .airHide, .airs_en:hover .airHide {
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);
  height: 120px;
  transition: height 0.3s;
}
.tempHum_en:hover .tempHumHide {
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);
  height: 130px;
  transition: height 0.3s;
}

.airSecond {
  width: 100%;
  height: 60px;
  background: rgba(238, 238, 238, 0.9);
  .airSecondBox {
    max-width: 1200px;
    margin: 0 auto;
    height: 60px;
    line-height: 60px;
  }
}
.third {
  position: relative;
  height: 50px;
  line-height: 50px;
  width: 100%;
  text-align: center;
  // overflow: hidden;
  z-index: 2;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);
  ul {
    position: relative;
    margin: 0 auto;
    // overflow: hidden;
    background: rgba(255, 255, 255, 0.95);
    > :nth-child(1):after {
      content: '';
      position: absolute;
      width: 1000px;
      height: 50px;
      right: 100%;
      // background: rgba(255, 255, 255, .95);
    }
    > :nth-child(3):after {
      content: '';
      position: absolute;
      // width: 1000px;
      // height: 50px;
      left: 100%;
      // background: rgba(255, 255, 255, .95);
    }
  }
    .shopArrow {
      position: relative;
      top: 3px;
      margin-left: -18px;
      display: inline-block;
      width: 8px;
      height: 14px;
      background: url(./G_more_blank.png) center no-repeat;
      background-size: 5.33px 9.33px;
      transform: rotateZ(90deg);
      cursor: pointer;
  
      .wrapMall {
        display: none;
        position: absolute;
        top: -40px;
        left: 22px;
        transform: rotateZ(-90deg);
  
        .mallBox {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          width: 130px;
          list-style: none;
          background-color: #fff;
          font-size: 15px;
          border-radius: 8px;
          box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);
  
          .mallItem {
            width: 100%;
            padding-left: 17px;
            color: #000;
            cursor: pointer;
            text-align: left;
  
            span {
              width: 100%;
              margin-left: 0;
              opacity: 1;
              display: inline-block;
              color: #000;
              text-decoration: none;
            }
          }
  
          .mallItem:hover {
            span {
              opacity: .5;
            }
          }
        }
      }
    }
  
        .shopArrow:hover .wrapMall {
      display: inline-block;
    }
  li {
    display: inline-block;
    position: relative;
    margin: 0 30px;

    z-index: 2;
    a {
      opacity: 0.8;
      font-size: 15px;
      text-decoration: none;
      color: #000;
    }
  }
  :hover > .line {
    display: block;
  }

  .active {
    .line {
      display: block;
    }
  }
  .subBuy {
    position: relative;
    line-height: 1;
    display: inline-block;
    background: #2196f3;
    padding: 5px 12px;
    border-radius: 20px;
    cursor: pointer;
    border: 1px solid #fff;
    .buy_a {
      opacity: 1;
      display: inline-block;
      color: #fff;
      text-decoration: none;
    }
        .wrapMall {
          display: none;
          position: absolute;
          top: -50px;
          left: 22px;
    
          .mallBox {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 130px;
            list-style: none;
            background-color: #fff;
            font-size: 15px;
            border-radius: 8px;
            box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);
    
            .mallItem {
              width: 100%;
              padding-left: 17px;
              color: #000;
              cursor: pointer;
              text-align: left;
    
              span {
                width: 100%;
                margin-left: 0;
                opacity: 1;
                display: inline-block;
                color: #000;
                text-decoration: none;
              }
            }
    
    
            .mallItem:hover {
              span {
                opacity: .5;
              }
            }
          }
        }
        >:hover .wrapMall {
          display: inline-block;
        }
  }
  .subBuy:hover {
    background: #fff;
    color: #2196f3;
    border: 1px solid #2196f3;
    .buy_a {
      color: #2196f3;
    }
        
    .wrapMall {
      display: block;
    }
  }
  .coming {
    @extend .subBuy;
    cursor: default;
    color: #fff;
  }
  .coming:hover {
    background: #2196f3;
    color: #fff;
    border: 1px solid #fff;
    .buy_a {
      color: #fff;
    }
  }
  .line {
    display: none;
    position: absolute;
    left: 0;
    bottom: 10px;
    height: 2px;
    width: 100%;
    background: #b3b3b3;
    border-radius: 1px;
  }
}
.airSecondSubPosition {
  @extend .airSecond;
  position: fixed;
  top: 0;
  z-index: 3;
}
.thirdSubPosition {
  @extend .third;
  position: fixed;
  top: 0;
  z-index: 3;
}
.hide {
  display: none;
}
.airLogo {
  float: left;
  margin-top: 7px;
  line-height: 1;
  a {
    text-decoration: none;
    color: #424242;
    font-size: 20px;
    i {
      position: relative;
      top: 7px;
      display: inline-block;
      width: 29px;
      height: 29px;
      margin-right: 10px;
      background: url(./plus_logo.png) no-repeat center;
      background-size: 100%;
    }
    span {
    }
  }
}
.magpieLogo {
  float: left;
  margin-top: 7px;
  line-height: 1;
  a {
    text-decoration: none;
    color: #424242;
    font-size: 20px;
    i {
      position: relative;
      top: 7px;
      display: inline-block;
      width: 29px;
      height: 29.5px;
      margin-right: 10px;
      background: url(./magpie.png) no-repeat center;
      background-size: 100%;
    }
    span {
    }
  }
}
.airRightMenu {
  float: right;
  margin-top: 15px;
  line-height: 1;
  .ios {
    display: inline-block;
    width: 80px;
    height: 30px;
    background: url(./App_store.png) no-repeat center;
    background-size: 80px 30px;
    a {
      display: block;
      width: 80px;
      height: 30px;
    }
  }
  .ios_tw {
    display: inline-block;
    width: 80px;
    height: 30px;
    background: url(./App_store_tw.png) no-repeat center;
    background-size: 80px 30px;
    a {
      display: block;
      width: 80px;
      height: 30px;
    }
  }
  .ios_en {
    display: inline-block;
    width: 88.5px;
    height: 30px;
    background: url(./App_store_en.png) no-repeat center;
    background-size: 88.5px 30px;
    a {
      display: block;
      width: 88.5px;
      height: 30px;
    }
  }
  .android {
    display: inline-block;
    width: 98px;
    height: 30px;
    margin-left: 12px;
    background: url(./APK.png) no-repeat center;
    background-size: 98px 30px;
    a {
      display: inline-block;
      width: 98px;
      height: 30px;
    }
  }
  .android_tw {
    display: inline-block;
    width: 98px;
    height: 30px;
    margin-left: 12px;
    background: url(./APK_tw.png) no-repeat center;
    background-size: 98px 30px;
    a {
      display: inline-block;
      width: 98px;
      height: 30px;
    }
  }
  .android_en {
    display: inline-block;
    width: 98.5px;
    height: 30px;
    margin-left: 12px;
    background: url(./APK_en.png) no-repeat center;
    background-size: 98.5px 30px;
    a {
      display: inline-block;
      width: 98.5px;
      height: 30px;
    }
  }
  .google {
    display: inline-block;
    width: 99px;
    height: 30px;
    margin-left: 12px;
    background: url(./google-play-badge.png) no-repeat center;
    background-size: 99px 30px;
    a {
      display: inline-block;
      width: 99px;
      height: 30px;
    }
  }
  .wechat {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-left: 12px;
    background: url(./wechat.png) no-repeat;
    background-size: 100%;
    cursor: pointer;
    .qrcode {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 0;
      height: 0;
      padding-bottom: 0px;
      padding-right: 0px;
      background: url(./plus_code.png) no-repeat center;
      background-size: 100%;
      transition: padding-bottom 0.5s, padding-right 0.5s;
      z-index: 9;
    }
    .qrcode:hover {
      padding-bottom: 200px;
      padding-right: 200px;
    }
    .magpieCode {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 0;
      height: 0;
      padding-bottom: 0px;
      padding-right: 0px;
      background: url(./magpie_code.png) no-repeat center;
      background-size: 100%;
      transition: padding-bottom 0.5s, padding-right 0.5s;
      z-index: 9;
    }
    .magpieCode:hover {
      padding-bottom: 200px;
      padding-right: 200px;
    }
    .bg {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      opacity: 0;
      z-index: 1;
    }
  }
  .wechat:hover {
    .qrcode {
      padding-bottom: 200px;
      padding-right: 200px;
    }
    .magpieCode {
      padding-bottom: 200px;
      padding-right: 200px;
    }
  }
}
.purchaseHide {
  display: none;
  width: 330px;
  background: #2196f3;
  position: fixed;
  left: 50%;
  margin-top: 10px;
  margin-left: -165px;
  border-radius: 5px;
  font-size: 15px;
  text-align: left;
  color: rgba(255, 255, 255, 0.8);
  padding: 10px 12px;
  z-index: 1;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);
  a {
    color: rgba(255, 255, 255, 0.8);
  }
  span {
    position: relative;
    display: inline-block;
    float: left;
    padding-left: 47px;
    background: url(./purchase.png) no-repeat center left;
    background-size: 37px 37px;
    z-index: 2;
  }
  .purchaseBg {
    position: fixed;
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }
}
.purchaseShow {
  @extend .purchaseHide;
  display: block;
  .purchaseBg {
    width: 100%;
  }
}
