@import "./../../../../../scss/pc/reset";
.box {
	position: relative;
    width: 100%;
    background: #fff;
    padding: 50px 0 0 0;
}
.Gparam {
    display: table;
    width: 900px;
    margin: 0 auto;
}
.row {
    display: table-row;
    border-bottom: 1px solid #eeeeee;
    @extend %clearfix;
}
.name {
    display: table-cell;
    width: 150px;
    text-align: right;
    vertical-align: middle;
    border-bottom: 1px solid #eeeeee;
    @extend %little;
    font-weight: bold;
}
.cell {
    display: table-cell;
    padding: 50px 0px 50px 100px;
    border-bottom: 1px solid #eeeeee;
    @extend %normal;
    font-weight: normal;
    line-height: 1.6;
    ul {
        li {
            margin-bottom: 25px;
            display: flex;
            flex-direction: row;
            align-items: center;
            .type {
                display: inline-block;
                width: 180px;
                opacity: .6;
                font-weight: normal;
            }
            .type_new {
                display: inline-table;
                width: 180px;
                opacity: .6;
                font-weight: normal;
            }
            .homekit, .support {
                display: inline-block;
            }
            .homekit {
                width: 164.5px;
                height: 48px;
                background: url('./HomeKit.png') no-repeat;
                background-size: 100% auto;
            }
            .mihome {
                width: 79px;
                height: 39.5px;
                background: url('./mi.png') no-repeat;
                background-size: 100%;
            }
            .mihome_en {
                width: 125px;
                height: 47px;
                background: url('./mi_en.png') no-repeat;
                background-size: 100%;
            }
            .support {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                .item {
                    margin-right: 65px;
                    display: inline-flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    .logo_title {
                        margin-left: 12px;
                        font-size: 15px;
                    }
                    .home_logo {
                        width: 39.5px;
                        height: 39.5px;
                        background: url('./home.png') no-repeat;
                        background-size: 100% 100%;
                    }
                    .plus_logo {
                        width: 39.5px;
                        height: 39.5px;
                        background: url('./plus.png') no-repeat;
                        background-size: 100% 100%;
                    }
                    .mi_logo {
                        width: 39.5px;
                        height: 39.5px;
                        background: url('./mihome.png') no-repeat;
                        background-size: 100% 100%;
                    }
                }
            }
        }
    }
    p{
      letter-spacing: 0px;
      color: #000000;
      opacity: 0.5;
      font-size: 12px;
      font-weight: normal;
    }
}
.size {
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        .size_front {
            width: 219px;
            height: 240px;
            background: url('./front.png') no-repeat;
            background-size: 100%;
        }
        .size_side {
            width: 41.5px;
            height: 240.5px;
            background: url('./side.png') no-repeat;
            background-size: 100%;
        }
        .num {
            margin-top: 12px;
            font-size: 14px;
            opacity: .6;
        }
    }
}
.sizeImg {
    width: 344px;
    height: 265px;
    margin-bottom: 50px;
    background: url(./G_size.png) no-repeat center;
    background-size: 100%;
}
.pack {
    display: table-row;
    border-bottom: 1px solid #eeeeee;
    @extend %normal;
    // .name,
    // .cell {
    //     border-bottom: 0;
    // }
    @extend %clearfix;
    ul {
        padding: 0px 0 0;
        text-align: center;
        @extend %clearfix;
    }
    li {
        float: left;
        display: inline-block;
        margin: 8px 39.5px 8px 0;
        i {
            margin-bottom: 15px;
        }
        span {
            line-height: 1.2;
        }
    }

    .cell {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        .item {
            margin-right: 45px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .pack_1 {
                margin: auto;
                width: 110px;
                height: 110px;
                background: url('./pack_1.png') no-repeat;
                background-size: 100%;
            }
            .pack_2 {
                margin: auto;
                width: 51px;
                height: 110px;
                background: url('./pack_2.png') no-repeat;
                background-size: 100%;
                background-position: center;
            }
            .pack_3 {
                margin: auto;
                width: 50px;
                height: 110px;
                background: url('./pack_3.png') no-repeat;
                background-size: 100%;
                background-position: center;
            }
            .pack_4 {
                margin: auto;
                width: 110px;
                height: 110px;
                background: url('./pack_4.png') no-repeat;
                background-size: 100%;
            }
            .desc {
                margin-top: 25px;
                font-size: 15px;
                height: 30px;
            }
        }
    }
}
.packImg {
    width: 451px;
    height: 176px;
    background: url(./G_pack.png) no-repeat center;
    background-size: 100%;
}