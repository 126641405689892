@import "../../../../scss/mobile/reset.module";

.meun {
}
.hide {
    // display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    text-align: center;
    background: #333333;
    z-index: 11;
    opacity: 0;
    transition: opacity linear .5s;
    overflow: hidden;
}
.show {
    @extend .hide;
    opacity: 1;
    height: 100%;
    overflow: scroll;
    // display: block;
}
.nav {
    margin: 0 uc(30);
    li {
        width: 100%;
        line-height: uc(45);
        border-bottom: 1px solid #e0e0e0;
        font-size: 16px;
        color: #999;
        a {
            display: block;
            text-align: right;
            color: #999;
            text-decoration: none;
        }
    }
}

.full_logo {
    float: left;
    margin: uc(29/3) 0 0 uc(64/3);
    a {
        display: block;
        i {
            display: block;
            margin-top: uc(2);
            width: uc(152/2);
            height: uc(66/3);
            background: url(./logo2.png) no-repeat center left;
            background-size: uc(152/2) uc(27/2);
        }
    }
}
.buy {
    width: uc(65);
    height: uc(27);
    margin: uc(90) auto;
    background-color: #424242;
    border-radius: uc(27);
    line-height: uc(27);
    font-size: 15px;
    a {
        color: #fff;
        text-decoration: none;
    }
}
.hide .homeCur,
.hide .specCur {
    color: #000;
}
.line {
    display: block;
    height: uc(1);
    margin: uc(124/3) uc(20) 0 uc(20);
    background: #eeeeee;
    opacity: .1;
}
// [data-dpr="2"] .IconOpacity {
//     font-size: 30px;
// }
// [data-dpr="2"] .nav {
//     font-size: 32px;
// }
// [data-dpr="2"] .meun_footer {
//     a {
//         font-size: 32px;
//     }
//     p {
//         font-size: 32px;
//     }
// }

// [data-dpr="3"] .IconOpacity {
//     font-size: 45px;
// }
// [data-dpr="3"] .nav {
//     font-size: 48px;
// }
// [data-dpr="3"] .meun_footer {
//     a {
//         font-size: 48px;
//     }
//     p {
//         font-size: 48px;
//     }
// }
