@import './../../../../../scss/pc/reset';

.Psixth {
  position: relative;
  height: 806px;
  width: 100%;
  background: #ffffff url(./bc.png) no-repeat center;
  text-align: center;
  display: flex;
  justify-content: center;
  color: #000000;
  a {
    color: #000000;
    text-decoration: none;
  }
  a:hover {
    color: #000;
  }
}
.wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.alltext {
  position: relative;
  top: 50px;
  opacity: 0;
  transition: opacity 0.8s, top 0.8s;
}
.text {
  padding-top: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .p_sixth_logo {
    margin-right: 15px;
    width: 85px;
    height: 85px;
    background: url(./logo.png) no-repeat center;
    background-size: cover;
  }
}
.dec {
  p {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.6;
  }
}
.large {
  @extend %large;
  opacity: 0.9;
  padding-bottom: 0;
}
.imgList {
  width: 598.5px;
  height: 335px;
  background: url(../Pimg/9-2-cn.png) center no-repeat;
  background-size: cover;
  position: relative;
  top: 94px;
  margin: auto;
}
.imgList_tw {
  width: 598.5px;
  height: 335px;
  background: url(../Pimg/9-2-cn.png) center no-repeat;
  background-size: cover;
  position: relative;
  top: 94px;
  margin: auto;
}
.imgList_en {
  width: 598.5px;
  height: 335px;
  background: url(../Pimg/9-2EN.png) center no-repeat;
  background-size: cover;
  position: relative;
  top: 94px;
  margin: auto;
}
