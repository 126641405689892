@import "\./\.\./\.\./\.\./\.\./scss/mobile/reset.module";

.contact {
	position: relative;
    background: #fff;
    padding: uc(224/3) uc(60/3) uc(60/3);
    .list {
        margin-bottom: uc(80/3);
        border-bottom: 1px solid #eeeeee;
        .contact_title {
            margin-bottom: uc(80/3);
            line-height: 1;
            @include small;
            opacity: .5;
        }
        .wechat {
            display: inline-block;
            width: uc(65/3);
            height: uc(52/3);
            background: url(./wechat.png) no-repeat center;
            background-size: 100%;
        }
        .sina {
            display: inline-block;
            width: uc(65/3);
            height: uc(54/3);
            background: url(./sina.png) no-repeat center;
            background-size: uc(65/3) uc(54/3);
        }
        .care_icon,
        .tel_email {
            @include normal;
            padding-bottom: 0;
            opacity: 1;
            li {
                margin: 0 0 uc(80/3) 0;
            }
            i {
                position: relative;
                top: uc(3);
                margin-right: uc(18);
            }
            a {
                color: #414141;
                text-decoration: none;
                span {
                    display: inline-block;
                    line-height: 1.2;
                    border-bottom: 1px #000 solid;
                }
            }
            .tel {
                display: inline-block;
                width: uc(52/3);
                height: uc(46/3);
                background: url(./tel.png) no-repeat center;
                background-size: uc(46/3);
            }
            .email {
                display: inline-block;
                width: uc(52/3);
                height: uc(46/3);
                background: url(./email.png) no-repeat center;
                background-size: uc(52/3) uc(37/3);
            }
        }
        .tel_email {
            // a {
            //     border-bottom: 1px #000 solid;
            // }
        }
        .care_icon {
            text-decoration: none;
            a {
                text-decoration: none;
            }
        }
    }
    .lastList {
        @extend .list;
        border-bottom: 0;
    }
}
.wechat_hide {
    @extend %clearfix;
    height: 0;
    overflow-y: hidden;
    transition: height .3s;
    i {
        float: left;
        display: inline-block;
        margin-top: uc(20);
        margin-left: uc(10);
        width: uc(350/3);
        height: uc(350/3);
        background: url(./qrcode.jpg) no-repeat center;
        background-size: uc(350/3) uc(350/3);
    }
    span {
        @include normal;
        display: inline-block;
        margin: uc(65) 0 0 0;
        opacity: 1;
    }
}
.show {
    @extend .wechat_hide;
    height: uc(135);
}
.normal {
    @include normal;
}
.large {
    @include large;
}