@import './../../../../../scss/pc/reset';

.Seighth {
  position: relative;
  width: 100%;
  color: #000;
  background-color: #fff;
}

.wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 1240px;

  .pic {
    margin: 20px 0;
    width: 620px;
    height: 660px;
    background: url(./bg.png) center no-repeat;
    background-size: cover;
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 150px;
    width: 50%;
    box-sizing: border-box;

    .title {
      @extend %large_new;
      padding-bottom: 10px;
    }
    .desc {
      @extend %normal_new;
    }
    .img {
      align-self: center;
      margin-bottom: -135px;
      width: 240px;
      height: 441px;
      background: url(./17.png) center no-repeat;
      background-size: cover;
    }
    .img_tw {
      align-self: center;
      margin-bottom: -135px;
      width: 240px;
      height: 441px;
      background: url(./17_tw.png) center no-repeat;
      background-size: cover;
    }
    .img_en {
      @extend .img;
      background: url(./17_en.png) center no-repeat;
      background-size: cover;
    }

  }
  
  
}
