@import './../../../../../scss/pc/reset';

.Ptenth {
  position: relative;
  width: 100%;
  height: 765px;
  text-align: center;
  background: url(../Pimg/7.jpg) center no-repeat;
  background-size: auto 765px;
  color: #000;
}
.text {
  padding-top: 100px;
  position: relative;
  top: 50px;
  opacity: 0;
  transition: opacity 0.8s, top 0.8s;
}
.special {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  .left,
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px;
    margin-top: 10px;
    .leftIcon {
      margin-bottom: 10px;
      width: 70px;
      height: 70px;
      background: url(../Pimg/7-1.png) center no-repeat;
      background-size: 70px;
    }
    .rightIcon {
      margin-bottom: 10px;
      width: 70px;
      height: 70px;
      background: url(../Pimg/7-2.png) center no-repeat;
      background-size: 70px;
    }
    .thirdIcon {
      margin-bottom: 10px;
      width: 70px;
      height: 70px;
      background: url(../Pimg/7-3.png) center no-repeat;
      background-size: 70px;
    }
    .rightText {
      font-size: 15px;
      opacity: 0.8;
    }
  }
}

.large {
  @extend %large;
}
.normal {
  @extend %normal;
  opacity: 0.8;
}
