@import "\./\.\./\.\./\.\./\.\./scss/mobile/reset.module";

.Bprivacy {
    padding: uc(20);
    line-height: 1.5;
    font-size: 10.5pt;
    font-family: 宋体;
    word-wrap: break-word;
    .pt18 {
        font-size: 18pt;
    }
    .pt12 {
        font-size: 12pt;
    }
    .pt135 {
        font-size: 13.5pt;
    }
    .list {
        list-style-type: none
    }
    p {
        padding: 5px 0;
    }
    b {
        font-weight: bold;
    }
    ul {
        padding: 5px 0;
    }
    li {
        list-style-type: disc;
        margin-left: 15px;
    }
    a {
        color: #000;
    }
}