@import './../../../../../scss/pc/reset';

.Ptimecalculator {
  position: relative;
  height: 959px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(../Pimg/5-2.png) center no-repeat;
  background-size: auto 959px;
  color: #000;
  .title {
    margin-bottom: 50px;
    font-size: 36px;
    font-weight: bold;
    opacity: 0.9;
    .subtitle {
      font-size: 20px;
      font-weight: normal;
      opacity: 0.8;
    }
  }
  .chooseType {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    .frog {
      width: 233px;
      height: 344px;
      background: url(../Pimg/5-1.png) center no-repeat;
      background-size: 233px 344px;
    }
    .frogName {
      font-size: 15px;
      opacity: 0.5;
    }
  }

  .content {
    .items {
      display: flex;
      flex-direction: row;
      margin-bottom: 65px;
      .item {
        text-align: left;
        margin-right: 30px;
        .item_title {
          margin-bottom: 10px;
          font-size: 12px;
          opacity: 0.5;
        }
      }
    }
    .cal_content {
      .cal_title {
        font-size: 15px;
        opacity: 0.7;
      }
      .cal_result {
        margin: 10px 0;
        .cal_num {
          font-size: 34px;
          font-weight: bold;
        }
      }
      .desc {
        font-size: 12px;
        opacity: 0.7;
      }
    }
  }
}
