@import "./../../../../../scss/pc/reset";

.Dtenth {
    position: relative;
    width: 100%;
    height: 500px;
    background: url(./tenth.png) no-repeat center;
    background-size: auto 100%;
    background-color: #d6d6d6;
    text-align: center;
    color: #000;
}
.desc {
    padding-top: 100px;
    @extend %little;
    opacity: .6;
}
.text {
    @extend %normal;
    line-height: 1.5;
    opacity: .8;
}
.battery {
    position: absolute;
    left: 50.5%;
    bottom: 152px;
    width: 44px;
    height: 30px;
}
.ten_5 {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(./ten_5.png) no-repeat center;
    background-size: 100%;
    transition: opacity 1s;
}
.ten_4 {
    @extend .ten_5;
    background: url(./ten_4.png) no-repeat center;
    background-size: 100%;
}
.ten_3 {
    @extend .ten_5;
    background: url(./ten_3.png) no-repeat center;
    background-size: 100%;
}
.ten_2 {
    @extend .ten_5;
    background: url(./ten_2.png) no-repeat center;
    background-size: 100%;
}
.ten_1 {
    @extend .ten_5;
    background: url(./ten_1.png) no-repeat center;
    background-size: 100%;
}
.ten_0 {
    @extend .ten_5;
    background: url(./ten_0.png) no-repeat center;
    background-size: 100%;
}
