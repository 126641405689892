@import "./../../../../../scss/pc/reset";

.Pseventh {
    position: relative;
    padding: 100px 0 90px 0;
    width: 100%;
    height: 720px;
    background: url(./P_seventh_img.jpg) no-repeat center;
    background-size: 100% 720px;
    box-sizing: border-box;
    text-align: center;
    color: #2c2c2c;
    min-width: 1100px;
}
.text {
    position: relative;
    top: 50px;
    margin: auto;
    max-width: 600px;
    opacity: 0;
    transition: opacity .8s, top .8s;
}
.large {
    @extend %large;
}
.normal {
    @extend %normal;
    margin: auto;
    margin-top: 20px;
    opacity: .6;
    max-width: 620px;
}
.normal_en {
    @extend .normal;
    max-width: 540px;
}
.imgList {
    width: 600px;
    margin: auto;
    margin-top: 70px;
    text-align: center;
    ul {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        li {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            // margin: 0 58px;
            i {
                display: block;
            }
        }
        span {
            @extend %little;
            display: block;
            margin-bottom: 20px;
            opacity: .6;
        }
        .p_seventh_img_1 {
            width: (350px/2);
            height: (592px/2);
            background: url(./P_seventh_img_1.png) no-repeat center;
            background-size: cover;
        }
        .p_seventh_img_2 {
            width: (350px/2);
            height: (592px/2);
            background: url(./P_seventh_img_2.png) no-repeat center;
            background-size: cover;
        }
        .p_seventh_img_1_tw {
            width: (350px/2);
            height: (592px/2);
            background: url(./P_seventh_img_1_tw.png) no-repeat center;
            background-size: cover;
        }
        .p_seventh_img_2_tw {
            width: (350px/2);
            height: (592px/2);
            background: url(./P_seventh_img_2_tw.png) no-repeat center;
            background-size: cover;
        }
        .p_seventh_img_1_en {
            width: (350px/2);
            height: (592px/2);
            background: url(../assets/12_en.png) no-repeat center;
            background-size: cover;
        }
        .p_seventh_img_2_en {
            width: (350px/2);
            height: (592px/2);
            background: url(./P_seventh_img_2_en.png) no-repeat center;
            background-size: cover;
        }
    }
}