@import "./../../../../../scss/pc/reset";

.Sfourth {
    color: #000;
    width: 100%;
    background-color: #fff;
}
.wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 100px 0 20px 0;
    box-sizing: border-box;
}
.text {
    padding-bottom: 10px;
    text-align: center;
    width: 660px;
    .title {
        @extend %large_new;
        padding-bottom: 10px;
        .footnote {
            position: relative;
            vertical-align: baseline;
            font-size: 24px;
            opacity: .7;
            a {
              vertical-align: inherit;
              color: inherit;
            }
            a:hover {
              color: #06c;
            }
          }
    }
    .subtitle {
        @extend %normal_new;
        opacity: .6;
        .footnote {
            position: relative;
            vertical-align: baseline;
            font-size: 10px;
            opacity: .7;
            a {
              vertical-align: inherit;
              color: inherit;
            }
            a:hover {
              color: #06c;
            }
          }
    }
    .numBox {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .num_item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 20px;
            .item_title {
                @extend %normal_new;
                padding-bottom: 0px;
                opacity: .6;
            }
            .item_num {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: baseline;
                font-size: 35px;
                font-weight: 300;
                .unit {
                    @extend %normal_new;
                }
            }
        }
    }
}
.imgBox {
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    .pic {
        height: 565.5px;
        background: url(./bg.png) center no-repeat;
        background-size: contain;
    }
    .pic_en {
        @extend .pic;
        background: url(./bg_en.png) center no-repeat;
        background-size: contain;
    }
}
.memo {
    @extend %little_new;
    font-size: 36px;
    font-weight: bold;
    padding: 30px;
    width: 100%;
    color: #fff;
    text-align: center;
    background-color: #7b92b9;
    box-sizing: border-box;
    .footnote {
        position: relative;
        vertical-align: baseline;
        font-size: 21px;
        opacity: .7;
        a {
          vertical-align: inherit;
          color: inherit;
        }
        a:hover {
          color: #06c;
        }
      }
}


.large {
    @extend %large_new;
}
.normal {
    @extend %normal_new;
}
.little {
    @extend %little_new;
}
.small {
    @extend %small_new;
}