@import "./../../../../../scss/pc/reset";

.Dthird {
    width: 100%;
    height: 600px;
    background: url(./third.png) no-repeat center;
    background-size: cover;
    text-align: center;
    color: #fff;
}
.title {
    @extend %large;
    padding-top: 100px;
    opacity: .9;
}
.desc {
    @extend %little;
    opacity: .8;
}
.text {
    @extend %normal;
    line-height: 1.5;
    opacity: .8;
}
