@import "\./\.\./\.\./\.\./\.\./scss/mobile/reset.module";

.airContent {
    position: relative;
    .airBg {
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background: url(./bg.png) no-repeat center top;
        background-size: cover;
        // background-attachment:fixed;
    }
    > ul {
        // width: 100%;
        // height: 100%;
        // background: url(./air_bg_2.jpg) no-repeat center;
        // // background-size: cover;
        // background-attachment:fixed;
        // opacity: 0;
        > li {
            width: 100%;
            height: 100%;
        }
        .air_one {
            position: relative;
            z-index: 9;
        }
        .air_one, .air_three {
          min-height: uc(1512/3);
            .air_one_text {
                padding-top: uc(150);
                position: relative;
                color: #fff;
                margin: 0 uc(15);
                z-index: 9;
                text-align: center;
                .ul_box {
                    width: uc(140);
                    margin: auto;
                    li {
                        text-align: center;
                        list-style-type: none;
                    }
                }
                .ul_box_en {
                    width: uc(145);
                    margin: auto;
                    li {
                        text-align: left;
                        list-style-type: none;
                    }
                }
                span {
                    display: block;
                }
                .slgon {
                    font-size: uc(42/3);
                    font-weight: normal;
                }
            }
            .btn {
                width: uc(441/3);
                height: uc(109/3);
                margin: uc(20) auto;
                background: #fff;
                border-radius: uc(109/3);
                text-align: center;
                line-height: uc(109/3);
                a {
                    color: #000;
                    text-decoration: none;
                }
                @include normal;
                line-height: 2.5;
                padding: 0;
                opacity: 1;
            }
            .download {
                width: 100%;
                line-height: uc(42/3);
                text-align: center;
                a {
                    display: block;
                    color: #fff;
                    font-size: uc(42/3);
                    text-decoration: none;
                }
                .handline {
                    margin: auto;
                    margin-top: 1px;
                    width: uc(116);
                    height: 1px;
                    background-color: #fff;
                }
            }
            .air_three_text {
                position: relative;
                // top: uc(350);
                color: #fff;
                margin: 0 uc(15);
                line-height: 1.8;
                z-index: 9;
                text-align: center;
                li {
                    list-style-type: none;
                }
                span {
                    display: block;
                }
            }
        } 
        .air_two {
            position: relative;
            min-height: uc(1634/3);
            .air_two_img {
                width: 100%;
                height: 100%;
                margin: uc(0) auto 0 auto;
                top: 0;
                background: url(./1.png) no-repeat center;
                background-size: uc(726/3) uc(1199/3);
                transition: opacity .5s;
                z-index: 8;
            }
            .air_two_img_tw {
                width: 100%;
                height: 100%;
                margin: uc(0) auto 0 auto;
                top: 0;
                background: url(./1_tw.png) no-repeat center;
                background-size: uc(726/3) uc(1199/3);
                transition: opacity .5s;
                z-index: 8;
            }
            .air_two_img_en {
                width: 100%;
                height: 100%;
                margin: uc(0) auto 0 auto;
                top: 0;
                background: url(./1_en.png) no-repeat center;
                background-size: uc(726/3) uc(1199/3);
                transition: opacity .5s;
                z-index: 8;
            }
        }
    }
}
.logo {
    display: block;
    width: uc(174/3);
    height: uc(174/3);
    margin: 0 auto uc(40) auto;
    background: url(./logo.png) no-repeat center top;
    background-size: 100%;
}
.toBrowser {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 11;
    .arrow {
        position: absolute;
        top: uc(10);
        right: uc(20);
        width: uc(128);
        height: uc(77);
        background: url(./Darrow.png) no-repeat center;
        background-size: 100%;
    }
    .toBrowserText {
        margin-top: uc(50);
        text-align: center;
        font-size: 16px;
        line-height: uc(40);
        span {
            color: #007aff;
        }
    }
    .toBrowserPhone {
        position: relative;
        left: uc(-95);
        width: uc(243);
        height: uc(365);
        margin: uc(20) auto 0 50%;
        background: url(./android_pnone.png) no-repeat center;
        background-size: 100%;
    }
}
.toBrowserShow {
    @extend .toBrowser;
    display: block;
}
.large {
    @include large;
    opacity: 1;
    padding-bottom: uc(18);
}
.normal {
    @include normal;
}
.normal_p{
  @include normal;
  padding-top: uc(42/3);
}
.little {
    @include little;
}