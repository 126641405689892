@import './../../../../../scss/pc/reset';

.Psecond {
  width: 100%;
  height: 899px;
  background: url(../Pimg/6.jpg) no-repeat center;
  background-size: auto 899px;
  text-align: center;
  color: #fff;
}
.text {
  position: relative;
  top: -50px;
  opacity: 0;
  transition: opacity 0.8s, top 0.8s;
  span {
    display: block;
  }
}
.large {
  @extend %large;
  padding-top: 100px;
}
.little {
  @extend %little;
  opacity: 0.6;
}
.iconList {
  @extend %normal;
  width: 1000px;
  margin: 420px auto 0 auto;
  position: relative;
  top: 50px;
  font-size: 15px;
  opacity: 0;
  transition: opacity 0.8s, top 0.8s;
  li {
    width: 33%;
    float: left;
    line-height: 1.6;
    i {
      display: inline-block;
      margin: 0 auto 40px auto;
    }
    span {
      display: block;
    }
    .bottomText {
      span {
        display: inline;
      }
      .footnote {
        position: relative;
        vertical-align: baseline;
        font-size: 8px;
        opacity: 0.7;
        a {
          vertical-align: inherit;
          color: inherit;
        }
        a:hover {
          color: #06c;
        }
      }
    }
    .second_lora {
      width: (297px/2);
      height: (183px/2);
      background: url(./P_second_lora.png) no-repeat center;
      background-size: (297px/2) (183px/2);
    }
    .second_nbiot {
      width: (485px/2);
      height: (183px/2);
      background: url(./P_second_nbiot.png) no-repeat center;
      background-size: (485px/2) (172px/2);
    }
    .second_wifi {
      width: (260px/2);
      height: (183px/2);
      background: url(./P_second_wifi.png) no-repeat center;
      background-size: (260px/2) (172px/2);
    }
  }
}
