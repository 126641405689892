@import "./../../../../../scss/pc/reset";

.Gtenth {
    position: relative;
    width: 100%;
    height: 725px;
    background: #eeeeee;
    overflow: hidden;
    i {
        display: inline-block;
        height: 260px;
    }
}
.row_1 {
    position: absolute;
    left: -200px;
    margin-top: 15px;
    transition: left .1s;
    .img_1 {
        background: url(./img_14.png) no-repeat center;
        background-size: cover;
        cursor: pointer;
    }
    .img_2 {
        margin-left: 15px;
        background: url(./img_15.png) no-repeat center;
        background-size: cover;
        cursor: pointer;
    }
    .img_3 {
        margin-left: 15px;
        background: url(./img_16.png) no-repeat center;
        background-size: cover;
        cursor: pointer;
    }
}
.row_2 {
    position: absolute;
    left: 0px;
    margin-top: 290px;
    transition: left .1s;
    .img_4 {
        height: 435px;
        background: url(./img_17.png) no-repeat center;
        background-size: cover;
        cursor: pointer;
    }
    .img_5 {
        height: 435px;
        margin-left: 15px;
        background: url(./img_18.png) no-repeat center;
        background-size: cover;
        cursor: pointer;
    }
}
.hide {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    z-index: 10;
    .pic {
        position: relative;
        width: 960px;
        height: 635px;
        top: 50%;
        margin: -317px auto 0 auto;
    }
    .left {
        position: absolute;
        width: 75px;
        height: 150px;
        top: 50%;
        left: 0;
        margin: -75px 0 0 -75px;
        background: url(./right.png) no-repeat center;
        background-size: 100%;
        z-index: 2;
        transform: rotateY(180deg);
    }
    .left:hover {
        background: url(./left_cur.png) no-repeat center;
        background-size: 100%;
        transform: rotateY(0);
        cursor: pointer;
    }
    .right {
        position: absolute;
        width: 75px;
        height: 150px;
        top: 50%;
        right: 0;
        margin: -75px -75px 0 0;
        background: url(./right.png) no-repeat center;
        background-size: 100%;
        z-index: 2;
    }
    .right:hover {
        background: url(./left_cur.png) no-repeat center;
        background-size: 100%;
        transform: rotateY(180deg);
        cursor: pointer;
    }
    .items {
        position: absolute;
        width: 960px;
        height: 635px;
        top: 50%;
        left: 50%;
        margin-top: -317px;
        margin-left: -480px;
        overflow: hidden;
        ul {
            position: absolute;
            width: 4830px;
            left: 0;
            transition: left .5s ease-in;
        }
        li {
            display: inline-block;
        }
        .pic_1 {
            width: 960px;
            height: 635px;
            background: url(./img_1.jpg) no-repeat center;
            background-size: 100% auto;
        }
        .pic_2 {
            width: 960px;
            height: 635px;
            background: url(./img_2.jpg) no-repeat center;
            background-size: 100% auto;
        }
        .pic_3 {
            width: 960px;
            height: 635px;
            background: url(./img_3.jpg) no-repeat center;
            background-size: 100% auto;
        }
        .pic_4 {
            width: 960px;
            height: 635px;
            background: url(./img_4.jpg) no-repeat center;
            background-size: 100% auto;
        }
        .pic_5 {
            width: 960px;
            height: 635px;
            background: url(./img_5.jpg) no-repeat center;
            background-size: 100% auto;
        }
    }
}
.bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000;
}
.show {
    @extend .hide;
    display: block;
}
