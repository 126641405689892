@import './../../../../../scss/pc/reset';

.Pthird {
  position: relative;
  width: 100%;
  height: 608px;
  background: url(../Pimg/11.png) center no-repeat;
  background-size: auto 608px;
  text-align: center;
  color: #fff;
}
.wrap {
  position: relative;
  width: 100%;
  height: 100%;
}
.text {
  position: relative;
  opacity: 1;
  transition: opacity 0.8s, top 0.8s;
  span {
    display: block;
  }
}
.large {
  @extend %large;
  padding-top: 100px;
  opacity: 0.9;
}
.normal {
  @extend %little;
  opacity: 0.6;
}
.iconList {
  width: 100%;
  position: absolute;
  bottom: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: opacity 0.8s, bottom 0.8s;
  text-align: center;
  .container {
    width: 1000px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    span {
      width: 250px;
      font-size: 20px;
      opacity: 0.9;
    }
  }
}
