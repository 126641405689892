@import "./../../../../../scss/pc/reset";

.Dfirst {
    position: relative;
    width: 100%;
    height: 700px;
    background: url(./first.png) no-repeat center;
    background-size: cover;
    color: #000;
}
.text {
    width: 900px;
    height: 240px;
    margin: -120px 0 0 -450px;
    position: absolute;
    left: 50%;
    top: 50%;
    line-height: 1;
}
.pirce {
    margin-top: 50px;
    a {
        color: #5f8a2c;
        font-size: 18px;
        font-family: 'numFont';
        text-decoration: none;
        span {
            font-size: 48px;
        }
    }
    a:hover {
        opacity: .8;
    }
}
.line {
    margin: 0 5px;
}
.large {
    @extend %large;
    margin-bottom: 5px;
    line-height: 1;
    opacity: .7;
}
.little {
    @extend %little;
    line-height: 1.4;
    opacity: .6;
}
.normal {
    @extend %normal;
    margin-top: 5px;
    opacity: .8;
}
