@import "../../../../scss/mobile/reset.module";

.footer {
    position: relative;
    z-index: 9;
    padding: uc(22.5) uc(50);
    background: #fff;
    text-align: center;
    color: #999999;
    // background: #f3f3f3;
    @extend %clearfix;
    font-size: 12px;
    line-height: 1;
    a {
        margin-bottom: uc(10);
        color: #999;
    }
    p {
        padding: uc(7.5) 0 ;
    }
    .police {
        opacity: 1;
        padding: uc(2.5) 0 ;
        a {
            display:inline-block;
            text-decoration:none;
            height:20px;
            line-height:20px;
            margin-bottom: 0;
            p {
                float:left;
                height: 20px;
                line-height:20px;
                padding: 0;
                color: #939393;
            }
        }
        .police_img {
            // display: none;
            // float:left;
            width: 20px;
            height: 20px;
            background: url(./police.png) no-repeat;
            background-size: 100%;
        }
    }
    
        .records_p{
          background: url(./records.png) no-repeat left center;
          background-size: 14px 14px;
          padding-left: 20px;
           color:#939393;
           text-decoration: underline;
        }
    
}
.logo {
    i {
        display: inline-block;
        width: 81px;
        height: 16px;
        background: url(./footer_logo.png) no-repeat center;
        background-size: auto 100%;
        opacity: .8;
    }
}
.logo_en {
    i {
        display: inline-block;
        width: 100px;
        height: 16px;
        background: url(./footer_logo_en.png) no-repeat;
        background-size: 100% auto;
    }
}
.hide {
    display: none;
}
.show {
    display: inline-block;
}

.top_arrows {
    display: inline-block;
    vertical-align: bottom;
    margin-left: 8px;
    margin-bottom: 1px;
    width: uc(30/3);
    height: uc(23/3);
    background: url(./arrow.png) center no-repeat;
    background-size: 100%;
}
.top_arrows_selected {
    @extend .top_arrows;
    transform: rotateZ(180deg);
}

.wrapBuy {
    // display: none;
    position: absolute;
    top: -115px;
    left: 10px;
    .buyBox {
        width: 96px;
        list-style: none;
        background-color: #fff;
        font-size: 15px;
        border-radius: 8px;
        box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);
        .buyItem {
            padding: 17px 0;
            border-bottom: 1px solid #e5e5e5;
            color: #2196f3;
            cursor: pointer;
            text-align: center;
            a {
                margin-left: 0;
                opacity: 1;
                display: inline-block;
                color: #2196f3;
                text-decoration: none;
            }
        }:last-child {
            border-bottom: none;
        }
        .buyItem:hover {
            a {
                opacity: .5;
            }
        }
    }
}