@import "./../../../../../scss/pc/reset";

.Sfourteenth {
    width: 100%;
    background-color: #f3f7fe;
    color: #000;
    .wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        .text {
            margin-top: 100px;
            .title {
                @extend %large_new;
                .footnote {
                    position: relative;
                    vertical-align: baseline;
                    font-size: 22px;
                    opacity: .7;
                    a {
                      vertical-align: inherit;
                      color: inherit;
                    }
                    a:hover {
                      color: #06c;
                    }
                  }
            }
        }
        .pic {
            margin: 30px 0 70px;
            width: 320px;
            height: 240px;
            background: url(./15.png) center no-repeat;
            background-size: 100%;
        }
    }
}