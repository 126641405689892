@import './../../../../../scss/pc/reset';

.Pfirst {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 100px;
  width: 100%;
  height: 1056px;
  color: #fff;
  box-sizing: border-box;
  background: url(../Pimg/1CN.jpg) center no-repeat;
  background-size: auto 1056px;
}
.Pfirst_en {
  @extend .Pfirst;
  height: 1180px;
  background: url(../Pimg/1EN.jpg) center no-repeat;
  background-size: auto 1180px;
}
.text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
}
.product {
  font-weight: normal;
  opacity: 0.8;
}
.product_en {
  padding-left: 20px;
  li {
    list-style-type: disc;
  }
}
.multiple {
  margin: 30px 0;
  width: 900px;
  text-align: center;
  font-size: 45px;
  line-height: 1.2;
  font-weight: 600;
}
.every {
  font-weight: normal;
  line-height: 1.4;
  opacity: 0.8;
}
.table {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 450px 0 20px;
  // padding: 10px 30px;
  width: 550px;
  border: none;
  // border: 1px solid #b1b2b2;
  border-radius: 15px;
  box-sizing: border-box;
}
.table_row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 5px 30px;
  font-size: 15px;
  line-height: 1.2;
  background-color: #0006;
  border-bottom: 1px solid #4d4f53;
}
.column0 {
  margin: 10px;
  width: 100px;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.column0_en {
  @extend .column0;
  margin: 5px;
  width: 150px;
  line-height: 1.4;
}
.column1 {
  margin: 10px;
  width: 200px;
  line-height: 1.2;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.column1_en {
  @extend .column1;
  margin: 5px;
  width: 180px;
  line-height: 1.4;
}
.column2 {
  @extend .column1;
  width: 150px;
  margin-left: 20px;
  line-height: 1.2;
  opacity: 0.8;
}
.column2_en {
  @extend .column2;
  margin: 5px;
  width: 180px;
  line-height: 1.4;
}
.title {
  font-size: 15px;
  line-height: 1.6;
  font-weight: bold;
  opacity: 1;
}
.spec {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: normal;
  span {
    color: #fff;
  }
  span:hover {
    color: #2196f3;
  }
}
a {
  text-decoration: none;
  color: #fff;
}
.triangle {
  width: 7px;
  height: 7px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(45deg);
}
