@import "./../../../../../scss/pc/reset";

.Sfifteen {
    position: relative;
    width: 100%;
    height: 618px;
    background: #fff;
    overflow: hidden;
    text-align: center;
    padding-top: 80px;
    i {
        display: inline-block;
        height: 512px;
    }
    .title {
        @extend %large_new;
    }
}
.row_1 {
    position: absolute;
    left: -200px;
    height: 512px;
    margin-top: 25px;
    transition: left .1s;
    .img_1 {
        background: url(./22.png) no-repeat center bottom;
        background-size: cover;
        cursor: pointer;
    }
    .img_2 {
        margin-left: 15px;
        background: url(./23.png) no-repeat center bottom;
        background-size: cover;
        cursor: pointer;
    }
    .img_3 {
        margin-left: 15px;
        background: url(./24.png) no-repeat center right;
        background-size: cover;
        cursor: pointer;
    }
}
.hide {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    z-index: 10;
    .pic {
        position: relative;
        width: 1280px;
        height: 724px;
        top: 50%;
        margin: -362px auto 0 auto;
    }
    .left {
        position: absolute;
        width: 75px;
        height: 150px;
        top: 50%;
        left: 0;
        margin: -75px 0 0 -75px;
        background: url(./right.png) no-repeat center;
        background-size: 100%;
        z-index: 2;
        transform: rotateY(180deg);
    }
    .left:hover {
        background: url(./left_cur.png) no-repeat center;
        background-size: 100%;
        transform: rotateY(0);
        cursor: pointer;
    }
    .right {
        position: absolute;
        width: 75px;
        height: 150px;
        top: 50%;
        right: 0;
        margin: -75px -75px 0 0;
        background: url(./right.png) no-repeat center;
        background-size: 100%;
        z-index: 2;
    }
    .right:hover {
        background: url(./left_cur.png) no-repeat center;
        background-size: 100%;
        transform: rotateY(180deg);
        cursor: pointer;
    }
    .items {
        position: absolute;
        width: 1280px;
        height: 724px;
        top: 50%;
        left: 50%;
        margin-top: -362px;
        margin-left: -640px;
        overflow: hidden;
        ul {
            position: absolute;
            width: 3840px;
            left: 0;
            transition: left .5s ease-in;
        }
        li {
            display: inline-block;
        }
        .pic_1 {
            width: 1280px;
            height: 724px;
            background: url(./22.png) no-repeat center;
            background-size: 100% auto;
        }
        .pic_2 {
            width: 1280px;
            height: 724px;
            background: url(./23.png) no-repeat center;
            background-size: 100% auto;
        }
        .pic_3 {
            width: 1280px;
            height: 724px;
            background: url(./24.png) no-repeat center;
            background-size: 100% auto;
        }
    }
}
.bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000;
}
.show {
    @extend .hide;
    display: block;
}
