@import "./../../../../scss/pc/reset";

.AboutUs {
  width: 100%;
  max-width: 850px;
  min-height: 360px;
  margin: 0 auto;
  padding: 100px 0 100px 0;
  line-height: 2;
  font-size: 15px;

  p {
    padding: 20px 0;
    line-height: 24px;
    color: #666;
  }

  b {
    font-weight: bold;
  }

  ul {
    padding: 5px 0;
  }

  li {
    list-style-type: disc;
    margin-left: 15px;
  }

  a {
    color: #000;
  }

  .title {
    text-align: left;
    font-size: 20px;
    color: #333;
  }

  .px20 {
    font-size: 20px;
    font-weight: bold;
    opacity: .8;
  }

  .px18 {
    font-size: 18px;
    font-weight: bold;
    opacity: .8;
  }
}