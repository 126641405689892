@import './../../../../../scss/pc/reset';

.box {
  position: relative;
  width: 100%;
  background: #fff;
  padding: 50px 0 30px 0;
}
.seperate_line {
  margin-bottom: 40px;
  width: 100%;
  height: 10px;
  background-color: #f2f2f2;
}
.param {
  display: table;
  width: 900px;
  margin: 0px auto;
}
.row {
  display: table-row;
  border-bottom: 1px solid #eeeeee;
  @extend %clearfix;
}
.title_row {
  @extend .row;
  display: table-caption;
  border-bottom: 1px solid #f2f2f2;
}
.name {
  display: table-cell;
  width: 150px;
  text-align: left;
  vertical-align: middle;
  border-bottom: 1px solid #eeeeee;
  @extend %little;
  font-weight: bold;
}
.title_name {
  @extend .name;
  padding-bottom: 40px;
  width: 240px;
  border-bottom: none;
}
.cell {
  display: table-cell;
  padding: 50px 0px 30px 100px;
  border-bottom: 1px solid #eeeeee;
  @extend %normal;
  font-weight: normal;
  line-height: 1;
  ul {
    li {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
      .type {
        display: inline-block;
        width: 150px;
        opacity: 0.6;
        font-weight: normal;
        line-height: 1.6;
      }
      .second_part {
        display: inline-block;
        span {
          margin-right: 40px;
          display: inline-block;
          line-height: 1.6;
        }
      }
    }
  }
  p {
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.5;
    font-weight: normal;
    font-size: 12px;
  }
}
.sizeImg {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 100px;
  height: 444px;
  .top_side {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .left_left {
      .left_img {
        width: 248px;
        height: 444px;
        background: url(./1.png) no-repeat;
        background-size: 100%;
      }
      .left_img_needle {
        width: 191px;
        height: 333px;
        background: url(./6.png) no-repeat;
        background-size: 100%;
      }
      .left_bottom {
        margin-top: 30px;
        width: 248px;
        height: 4px;
        border: 1px solid #7f7f7f;
        border-top: none;
      }
      .bottom_num {
        padding-top: 10px;
        width: 248px;
        text-align: center;
        opacity: 0.6;
      }
    }
    .left_right {
      margin-left: 20px;
      width: 444px;
      height: 4px;
      border: 1px solid #7f7f7f;
      border-left: none;
    }
    .right_num {
      position: absolute;
      right: 63px;
      height: 444px;
      transform: rotateZ(90deg);
      opacity: 0.6;
    }
  }
  .top_top {
    margin-left: 100px;
    position: relative;
    .top_img {
      width: 95px;
      height: 446px;
      background: url(./2.png) no-repeat;
      background-size: 100%;
    }
    .top_bottom {
      margin-top: 30px;
      width: 95px;
      height: 4px;
      border: 1px solid #7f7f7f;
      border-top: none;
    }
    .bottom_num {
      padding-top: 10px;
      width: 95px;
      text-align: center;
      opacity: 0.6;
    }
    .left_right {
      position: absolute;
      left: 100px;
      top: 0;
      margin-left: 20px;
      width: 4px;
      height: 444px;
      border: 1px solid #7f7f7f;
      border-left: none;
    }
    .right_num {
      position: absolute;
      right: 100px;
      top: 0;
      width: 80px;
      height: 444px;
      transform: rotateZ(90deg);
      opacity: 0.6;
    }
  }
}
.P_sizeImg {
  width: (758px/2);
  height: (529px/2);
  margin-bottom: 50px;
  background: url(./P_size.png) no-repeat center;
  background-size: 100%;
}
.pack {
  display: table-row;
  @extend %normal;
  .name,
  .cell {
    border-bottom: 0;
    ul {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding: 0px 0 0;
      text-align: center;
      @extend %clearfix;
    }
    li {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin: 8px 80px 8px 0;
      i {
        display: block;
        margin-bottom: 15px;
      }
      span {
        line-height: 1.2;
      }
    }
  }
  @extend %clearfix;
}
.pack_1 {
  width: 124px;
  height: 223px;
  background: url(./3.png) no-repeat bottom;
  background-size: 124px 223px;
}
.pack_2 {
  width: 70px;
  height: 223px;
  background: url(./4.png) no-repeat bottom;
  background-size: 70px 104px;
}
.pack_3 {
  width: 153px;
  height: 223px;
  background: url(./5.png) no-repeat bottom;
  background-size: 153px 110px;
}
.pack_7 {
  width: 111px;
  height: 193px;
  background: url(./7.png) no-repeat bottom;
  background-size: 111px 193px;
}
.pack_8 {
  width: 70px;
  height: 193px;
  background: url(./8.png) no-repeat bottom;
  background-size: 70px 70px;
}
.pack_9 {
  width: 40px;
  height: 193px;
  background: url(./9.png) no-repeat bottom;
  background-size: 40px 154px;
}
