@import "./../../../../../scss/pc/reset";
.box {
    position: relative;
    width: 100%;
    background: #fff;
    padding: 50px 0 30px 0;
}
.Sparam {
    display: table;
    width: 900px;
    margin: 0 auto;
}
.row {
    display: table-row;
    border-bottom: 1px solid #eeeeee;
    @extend %clearfix;
}
.name {
    display: table-cell;
    width: 150px;
    text-align: right;
    vertical-align: middle;
    border-bottom: 1px solid #eeeeee;
    @extend %little;
    font-weight: bold;
}
.cell {
    display: table-cell;
    padding: 50px 0px 50px 100px;
    border-bottom: 1px solid #eeeeee;
    @extend %normal;
    font-weight: normal;
    line-height: 1.6;
    ul {
        .type {
            display: inline-block;
            width: 180px;
            opacity: .6;
            font-weight: normal;
        }
        li {
            
            margin-bottom: 20px;
        }
        .special_li {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            line-height: 2;
        }
    }
    p{
        letter-spacing: 0px;
        color: #000000;
        opacity: 0.5;
        font-size: 12px;
        font-weight: normal;
    }
    .packWrap {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-left: 0px;
    }
}
.sizeImg {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 80px;
    height: 266px;
    .top_side {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        .left_left {
            .left_img {
                width: 205px;
                height: 211.5px;
                background: url('./1.png') no-repeat;
                background-size: 205px 211.5px;
            }
            .left_bottom {
                margin-top: 30px;
                width: 205px;
                height: 4px;
                border: 1px solid #7f7f7f;
                border-top: none;
            }
            .bottom_num {
                padding-top: 10px;
                width: 205px;
                text-align: center;
                opacity: .6;
            }
        }
        .left_right {
            margin-left: 20px;
            width: 4px;
            height: 211.5px;
            border: 1px solid #7f7f7f;
            border-left: none;
        }
        .right_num {
            position: absolute;
            right: 45px;
            height: 211.5px;
            transform: rotateZ(90deg);
            opacity: .6;
        }
    }
    .top_top {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-left: 100px;
        .left_left {
            .top_img {
                width: 91.5px;
                height: 211px;
                background: url('./2.png') no-repeat;
                background-size: 91.5px 211px;
            }
            .top_bottom {
                margin-top: 30px;
                margin-left: 0;
                width: 91.5px;
                height: 4px;
                border: 1px solid #7f7f7f;
                border-top: none;
            }
            .bottom_num {
                padding-top: 10px;
                width: 91.5px;
                text-align: center;
                opacity: .6;
            }
        }
        .left_right {
            margin-left: 20px;
            width: 4px;
            height: 211px;
            border: 1px solid #7f7f7f;
            border-left: none;
        }
        .right_num {
            position: absolute;
            right: -20px;
            height: 211px;
            transform: rotateZ(90deg);
            opacity: .6;
        }
    }
}
.pack {
    display: table-row;
    @extend %normal;
    .name,
    .cell {
        border-bottom: 0;
    }
    @extend %clearfix;
    ul {
        padding: 0px 0 0;
        text-align: center;
        @extend %clearfix;
    }
    li {
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin: 8px 40px 8px 0;
        i {
            margin: auto;
            margin-bottom: 15px;
        }
        span {
            line-height: 1.2;
        }
    }
}

.pack_1 {
    display: block;
    width: 140px;
    height: 133.5px;
    background: url(./3.png) no-repeat center;
    background-size: 129px 133.5px;
}
.pack_2 {
    display: block;
    width: 150px;
    height: 133.5px;
    background: url(./4.png) no-repeat center;
    background-size: 61.5px 61.5px;
}
.pack_3 {
    display: block;
    width: 133.5px;
    height: 133.5px;
    background: url(./5.png) no-repeat center;
    background-size: 133.5px 133.5px;
}

.snow_annotation{
  font-size: 12px;
	font-weight: normal;
	letter-spacing: 0px;
	color: #000000;
	opacity: 0.6;
}