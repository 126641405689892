
.slick-dots{
  position:relative;
  display: block;
  width: 136px;
  height: 30px;
  background-color: #EEEEF2;
  margin: auto;
  margin-top: 20px;
  overflow: hidden;
  border-radius: 50px;
  bottom: 0;
  
}
.slick-dots li{
  width: 30px;
  margin: 0;
  vertical-align: top;
  height: 8px;
  padding: 2px;
  margin-top: 9px;

}
.slick-dots li button{
  padding: 0;
  height: 8px;
  width: 100%;
}

.slick-dots li button:before {
  width: 100%;
  position: absolute;
  top: 2.5px;
  left: 50%;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  background-color: #707072;
  text-align: center;
  opacity: 0.8;
  border-radius: 8px;
  content: '';
  -webkit-font-smoothing: antialiased;
}
.slick-dots li.slick-active{
  width: 40px;
  margin: 0;
  margin-top: 9px;
  padding: 2px 0;
}
.slick-dots li.slick-active button::before{
  position: absolute;
  width: 40px;
  height: 8px;
  /* top: 0; */
  left: 50%;
  margin-left: -20px;
  background-color: #707072;
  border-radius: 8px;
  padding: 0;
}

/* .slick-dots li.slick-active button{
  width: 40px;
  height: 8px;
  background-color: #707072;
  border-radius: 8px;
  padding: 0;
} */

.slick-dots li.slick-active button::before{
  content: '';
}

.slick-arrow.slick-prev{
  display: none;

}
.slick-arrow.slick-next{
  display: none;
}
.slick-slide{
  cursor: pointer;
}
.slick-slide.slick-current{
  cursor: default;
}