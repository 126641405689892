@import ".\./\.\./\.\./\.\./\.\./scss/mobile/reset.module";

.design {
    position: relative;
    height: uc(436);
    padding-top: uc(124/3);
    img {
        height: 100%;
    }
}
.img {
    position: absolute;
    width: 100%;
    height: uc(436);
    line-height: 0;
    overflow: hidden;
    ul {
        position: absolute;
        display: block;
        width: 400%;
        height: uc(436);
        left: 0;
        transition: left 1s;
        li {
            position: relative;
            display: inline-block;
            width: 25%;
            height: 100%;
            float: left;
            > a {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .one_img {
            background: url(./GooseTM.jpg) no-repeat center;
            background-size: cover;
            position: relative;
            .goose_logo{
              position: absolute;
              bottom: uc(104/3);
              left: uc(100/3);
              width: uc(62);
              height: uc(31);
              background: url(./logo_color.png) no-repeat;
              background-size: 100% 100%;
            }
        }
        .two_img {
            background: url(./design_2.jpg) no-repeat center;
            background-size: cover;
        }
        .two_img_tw {
            background: url(./design_2_tw.jpg) no-repeat center;
            background-size: cover;
        }
        .two_img_en {
            background: url(./design_2_en.jpg) no-repeat center;
            background-size: cover;
        }
        .third_img {
            background: url(./design3.jpg) no-repeat center;
            background-size: cover;
        }
        .four_img {
            background: url(./magpie.png) no-repeat center;
            background-size: cover;
        }
        .frog_img {
            background: url(./pheasant_co2.jpg) no-repeat center;
                background-size: cover;
        }
    }
}
.btn {
    position: absolute;
    width: 100%;
    bottom: uc(20);
    text-align: center;
    z-index: 3;
    .li_wrap {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: auto;
        width: uc(82);
        padding: uc(3) uc(1.65);
        border-radius: uc(10);
        background-color: #0000004d;
        opacity: 1;
        .spot {
            display: inline-block;
            width: uc(26);
            height: uc(4.6);
            margin: 0 uc(1.65);
            border-radius: uc(5);
            background: rgba(255, 255, 255, .3);
            cursor: pointer;
        }
        .cur {
            @extend .spot;
            background: rgba(255, 255, 255, 1);
            cursor: default;
        }
    }
}
.text {
    position: absolute;
    top: 15%;
    color: #fff;
    text-align: right;
    line-height: 1;
    span {
        display: block;
    }
    .small {
        @include normal;
        opacity: 1;
    }
    .large {
        @include large;
        margin: uc(8) 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        .homekit {
            margin-left: uc(50/3);
            width: uc(329/3);
            height: uc(98/3);
            background: url('./HomeKit.png') no-repeat;
            background-size: 100% auto;
        }
        .mi {
            margin-left: uc(38/3);
            width: uc(188.9/3);
            height: uc(94/3);
            background: url('./mi.png') no-repeat;
            background-size: 100% uc(94/3);
        }
        .mi_en {
            margin-left: uc(38/3);
            width: uc(250/3);
            height: uc(94/3);
            background: url('./mi_en.png') no-repeat;
            background-size: 100%;
        }
    }
    .large_two {
        @extend .large;
        display: block;
    }
    .more {
        @include normal;
        display: inline-block;
        padding-right: 14px;
        font-size: uc(16);
        background: url(./G_more.png) no-repeat center right;
        background-size: 8px 14px;
        padding-bottom: 0;
        color: #fff;
        text-decoration: none;
        line-height: 1;
        opacity: 1;
    }
    .more_en {
        @extend .more;
        line-height: .65;
    }
}
.text_goose {
    @extend .text;
    left: 12%;
    text-align: left;
    color: #212121;
    .more {
        display: inline-block;
        background: url(./G_more_blank.png) no-repeat center right;
        background-size: 8px 14px;
        color: #212121;
    }
    .more_en {
        @extend .more;
        opacity: .65;
    }
}
.text_snow {
    @extend .text;
    text-align: left;
    width: uc(300);
    left: 10%;
    color: #212121;
    .more {
        background: url(./G_more_blank.png) no-repeat center right;
        background-size: 8px 14px;
        display: inline-block;
        // background-position: 70px 0;
        color: #212121;
    }
    .more_en {
        @extend .more;
        opacity: .65;
    }
}
.pheasant_text {
    @extend .text;
    right: 10%;
    top: auto;
    bottom: uc(80);
}
.magpie_text {
    @extend .text;
    right: 40%;
    top: 6%;
    text-align: left;
    // bottom: uc(80);
}
.magpie_text_en {
    @extend .text;
    color: #212121;
    left: 10%;
    top: 6%;
    text-align: left;
    .more {
        background: url(./G_more_blank.png) no-repeat center right;
        background-size: 8px 14px;
        color: #212121;
    }
    // bottom: uc(80);
}
.IF {
    position: absolute;
    left: uc(90/3);
    bottom: uc(230/3);
    z-index: 2;
    display: block;
    width: uc(58);
    height: uc(30);
    background: url(./IF.png) no-repeat center;
    background-size: 100%;
}
.hotdot {
    position: absolute;
    padding: uc(3);
    left: uc(90/3);
    bottom: uc(354/3);
    z-index: 2;
    display: block;
    width: uc(328/4);
    height: uc(90/4);
    background-image: url(./hotdot.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: uc(328/4) uc(90/4);
}