@import "../../../../scss/mobile/reset.module";

.header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
    box-shadow: 0 uc(5) uc(5) 0
        rgba(0, 0, 0, 0.1);
    background: #fff;
    @extend %clearfix;
}
.Icon {
    position: absolute;
    float: right;
    right: 0;
    padding: uc(42/3) uc(60/3) uc(12) uc(12);
    width: uc(48/3);
    height: uc(44/3);
    // margin-top: uc(42/3);
    background-size: uc(48/3) uc(44/3);
    z-index: 12;
    font-size: 14px;
    // transform:scaleX(1);
    // transition: transform linear .3s;
    i {
        display: block;
        width: uc(48/3);
        height: uc(2);
        border-radius: uc(2/2);
        background: #333333;
        opacity: .8;
    }
    .meun_1 {
        position: relative;
        width: uc(33/3);
        left: uc(15/3);
        transition: left .3s;
    }
    .meun_2 {
        margin: uc(12/3) 0;
    }
}
.IconCur {
    @extend .Icon;
    i {
        background: #fff;
    }
    .meun_1 {
        left: 0;
    }
    // transform:scaleX(-1);
    // filter:FlipH;
}
.logo {
    height: uc(66/3);
    padding-top: uc(34/3);
    padding-bottom: uc(24/3);
    background: rgba(255, 255, 255, .97);
    padding-left: uc(40/3);
    background: #fff;
    border-bottom: 1px #e5e5e5 solid;
    text-align: left;
    a {
        display: inline-block;
        line-height: 0;
        vertical-align: top;
    }
    i {
        display: inline-block;
        width: uc(152/2);
        height: uc(66/3);
        background: url(./logo.svg) no-repeat center left;
        background-size: uc(210/3) uc(37.8/3);
    }
}
.nav {
    width: 100%;
    position: relative;
    top: 0;
    line-height: uc(44);
    color: rgba(0, 0, 0, 0.8);
    // overflow: hidden;
    background-color: rgba(255, 255, 255, 0.95);
    @include normal;
    padding-bottom: 0;
    opacity: 1;
    .title {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: 0 uc(15);
        height: uc(41);
        line-height: uc(41);
        color: #000;
        padding: 0 0 0.13333rem 0;
        font-size: 0.32rem;
        font-weight: normal;
        opacity: 1;
        padding-bottom: 0;
        text-decoration: none;
        z-index: 10;
    }
    @extend %clearfix;
}
.secondSub {
    position: relative;
    @extend %clearfix;
}
.hide {
    // float: left;
    position: absolute;
    top: 0;
    width: 100%;
    height: uc(42);
    line-height: uc(42);
    background: #fff;
    transition: height .3s;
    z-index: 9;
    overflow: hidden;
    li {
        margin: 0 uc(15);
        height: uc(43);
        border-bottom: 1px solid #e5e5e5;
        a {
            color: #000;
            @include small;
            opacity: 1;
            padding-bottom: 0;
            text-decoration: none;
        }
    }
    .active {
        a {
            opacity: 1;
        }
    }
}
.arrow {
    width: uc(80/3);
    height: uc(46/3);
    margin: uc(80/6) 0 0 uc(5);
    background: url(./arrow.png) no-repeat center;
    background-size: uc(40/3) uc(23/3);
    border-bottom: 0;
    transform:rotate(0deg);
    transition: transform linear .3s;
    z-index: 10;
}
.arrowCur {
    @extend .arrow;
    transform:rotate(180deg);
}
.show {
    @extend .hide;
    height: uc(130);
    box-shadow: 0 uc(5) uc(5) 0
        rgba(0, 0, 0, 0.1);
    a{
        display: block;
        span {
            display: block;
            height: uc(41);
            line-height: uc(41);
        }
    }
    .active {
        a {
            opacity: .5;
        }
    }
}
.noDisplay {
    display: none;
}
.secondBuy {
    position: absolute;
    right: uc(40/3);
    top: uc(8.35);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: uc(40);
    background-color: #2196f3;
    border: 1px solid #fff;
    border-radius: uc(20);
    font-size: uc(12);
    font-weight: normal;
    line-height: 2;
    opacity: 1;
    text-align: center;
    z-index: 10;
    .buy_a {
        width: 100%;
        display: block;
        color: #fff;
        text-decoration: none;
    }
    span {
        // opacity: .8;
    }
}
.gooseSecondBuy {
    position: absolute;
    right: uc(40/3);
    top: uc(8.35);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: uc(40);
    background-color: #fff;
    border: 1px solid #2196f3;
    border-radius: uc(20);
    font-size: uc(12);
    font-weight: normal;
    line-height: 2;
    opacity: 1;
    text-align: center;
    z-index: 10;
    .buy_a {
        display: block;
        width: 100%;
        color: #2196f3;
        text-decoration: none;
    }
    span {
        opacity: .8;
    }
}
.parrotSecondBuy {
    @extend .secondBuy;
    right: uc(20/3);
    width: uc(90);
    color: #fff;
}
.buyBox {
    position: absolute;
    top: uc(42+5/3);
    right: uc(40/3);
    width: uc(450/3);
    // padding: 0 uc(10);
    list-style: none;
    background-color: #fff;
    font-size: uc(36/3);
    border-radius: 8px;
    text-align: center;
    z-index: 9;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.05);
    .buyItem {
        // padding: uc(42/3) 0;
        padding: 0 uc(10);
        border-bottom: 1px solid #e5e5e5;
        color: #2196f3;
        cursor: pointer;
        box-sizing: border-box;
        a {
            line-height: uc(38);
            color: #2196f3;
            text-decoration: none;
        }
    }:last-child {
        border-bottom: none;
    }
}
.parrotBuyBox {
    @extend .buyBox;
    width: uc(200);
}
.toBrowser {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 11;
    .arrow {
        position: absolute;
        top: uc(10);
        right: uc(20);
        width: uc(128);
        height: uc(77);
        background: url(./Darrow.png) no-repeat center;
        background-size: 100%;
    }
    .toBrowserText {
        margin-top: uc(50);
        text-align: center;
        font-size: 16px;
        line-height: uc(40);
        span {
            color: #007aff;
        }
    }
    .toBrowserPhone {
        position: relative;
        left: uc(-95);
        width: uc(244);
        height: uc(365);
        margin: uc(20) auto 0 50%;
        background: url(./android_pnone.png) no-repeat center;
        background-size: 100%;
    }
}
.toBrowserShow {
    @extend .toBrowser;
    display: block;
}
.purchaseHide {
    display: none;
    background: #2196f3;
    position: fixed;
    right: uc(15);
    left: uc(15);
    top: uc(105);
    border-radius: 5px;
    font-size: 12px;
    text-align: left;
    color: rgba(255, 255, 255, .8);
    padding: uc(10);
    z-index: 15;
    box-shadow: 0 uc(5) uc(5) 0
        rgba(0, 0, 0, 0.1);
    a {
        color: rgba(255, 255, 255, .8);
    }
    span {
        display: inline-block;
        float: left;
        padding-left: uc(74/3 + 10);
        background: url(./purchase.png) no-repeat center left;
        background-size: uc(74/3) uc(74/3);
    }
    i {
        display: inline-block;
        width: uc(38/3);
        height: uc(38/3);
        float: right;
        margin: uc(10) uc(10) 0 0;
        background: url(./purchase_close.png) no-repeat center right;
        background-size: uc(38/3) uc(38/3);
    }
}
.purchaseShow {
    @extend .purchaseHide;
    display: block;
    .purchaseBg {
        width: 100%;
    }
}
